@use "../util/" as *;

.letstalk {
  &__bg {
    // background: #ffffff;
    background: linear-gradient(90deg,#3b76ab,#4ca984);

    &--payroll {
      // background: #00ab9d;
    }

    &--leave {
      // background: #2196f3;
    }

    &--attendance {
      // background: #ef6c00;
    }

    &--claim {
      // background: #ef5350;
    }

    &--employeeprofile {
      // background: #78909c;
    }

    &--features {
      // background: #795548;
    }
  }

  &__title {
    font-size: rem(30);
    color: #ffffff;
    font-weight: bold;
    text-align: center;
    padding: 12px;

    @include breakpoint(medium) {
      font-size: rem(60);
    }
  }

  &__subtitle {
    font-size: rem(16);
    color: #ffffff;
    opacity: 0.6;
    text-align: center;

    @include breakpoint(medium) {
      font-size: rem(24);
    }
  }

  &__content {
    color: #ffffff;
  }

  &__cta {
    text-align: center;
    margin-top: 24px;
    display: flex;
    gap: 16px;
    justify-content: center;
  }

  &__button {
    display: flex;
    gap: 6px;
  }

  &__img {
    @include breakpoint(large) {
      position: absolute;
    }

    img {
      width: 30%;

      @include breakpoint(large) {
        width: 100%;
      }
    }

    &--left {
      display: flex;
      justify-content: flex-start;
      opacity: 0.7;

      img{
        max-width:60px;
        animation: tilt-shaking 3s linear infinite;
        // animation-delay: 2s;
      }

      @include breakpoint(large) {
        top: 0;
        left: 10%;

        img{
          max-width:180px;
        }
      }
    }

    &--right {
      display: flex;
      justify-content: flex-end;
      margin-top: 24px;
      opacity: 0.9;

      img{
        max-width:90px;
        animation: tilt-shaking 4s linear infinite;
        // animation-delay: 5s;
      }

      @include breakpoint(large) {
        bottom: 0;
        right: 10%;

        img{
          max-width:220px;
        }
      }
    }
  }
}

@keyframes tilt-shaking {
  0% { transform: rotate(0deg); }
  5% { transform: rotate(5deg); }
  10% { transform: rotate(0eg); }
  15% { transform: rotate(-5deg); }
  20% { transform: rotate(0deg); }
  100% { transform: rotate(0deg); }
}


