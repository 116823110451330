@use "../util/" as *;

.usage {
  &__bg {
    // background: linear-gradient(to top, rgb(21, 134, 125), rgba(255, 255, 255, 0));
  }
  &__row {
    display: flex;
    // gap: rem(32);
    margin: rem(-16);
    // flex-wrap: wrap;
  }

  &__col {
    width: 100%;
    margin: rem(16);

    @include breakpoint(medium) {
      width: 30%;
    }
  }

  &__col--right {
    width: 100%;
    display: none;
    margin: rem(16);

    @include breakpoint(medium) {
      width: 70%;
      display: block;
    }
  }

  &__accordion {
    // opacity: 0.4;
    display: flex;
    flex-wrap: wrap;
    width: 100%;
    border-radius: 8px;
    box-shadow: 0px 3px 6px 0px #00000029;
    // box-shadow: rgb(215 216 222 / 44%) 0px 13px 48px 0px;
    background-color: #ffffff;
    padding: rem(8) rem(16) rem(8) rem(16);
    margin-bottom: rem(16);
    position: relative;
    align-items: center;
    cursor: pointer;
    transition: all 0.3s;
    // height: 56px;

    @include breakpoint(medium) {
      padding: rem(8) rem(32) rem(8) rem(16);
      height: auto;
    }

    &:last-child {
      margin-bottom: 0;
    }

    &:before {
      content: "";
      height: rem(40);
      width: 3px;
      background-color: salmon;
      position: absolute;
      top: rem(8);
      left: 0;
      border-radius: 20px;
      transform-origin: top left;
      transform: scaleY(1);

      @include breakpoint(medium) {
        height: calc(100% - rem(16));
      }
    }

    // &.active:before {
    //   @include breakpoint(medium) {
    //     transform: scaleY(0);
    //     animation: underlineY 7s infinite;
    //   }
    // }

    &:after {
      content: "";
      background-image: url("../images/chevron-right.svg");
      position: absolute;
      opacity: 1;
      right: rem(8);
      width: rem(24);
      height: rem(24);
      background-size: 100%;
      top: rem(16);
      background-repeat: no-repeat;
      transition: all 0.3s;

      @include breakpoint(medium) {
        opacity: 0.3;
        background-size: 100%;
        width: rem(24);
        height: rem(24);
        top: 50%;
        transform: translate(0, -50%);
      }
    }

    &.active,
    &:hover {
      opacity: 1;
      @include breakpoint(medium) {
        transform: scale(1.05);
      }

      &:after {
        opacity: 1;
      }
    }

    &.active {
      // pointer-events: none;
      &:after {
        transform: rotate(90deg);
        @include breakpoint(medium) {
          transform: translate(0, -50%);
        }
      }
    }

    &.active {
      .accordion__content {
        // display: block;
        // opacity:1;
        // transform:translateY(0)
        // position: relative;
        // transform: scaleY(1);
        // height: rem(400);

        // &.height-auto {
        //   height: auto;
        // }
      }
      // pointer-events: none;
    }

    &.payroll {
      &:before {
        background-color: #26a69a;
      }
    }

    &.leave {
      &:before {
        background-color: #2196f3;
      }
    }

    &.attendance {
      &:before {
        background-color: #ef6c00;
      }
    }

    &.meal {
      &:before {
        background-color: #f06292;
      }
    }

    &.claim {
      &:before {
        background-color: #ef5350;
      }
    }

    &.award {
      &:before {
        background-color: #ba68c8;
      }
    }

    &.education {
      &:before {
        background-color: #43a047;
      }
    }

    &.disciplinary {
      &:before {
        background-color: #7986cb;
      }
    }

    &.employeeprofile {
      &:before {
        background-color: #78909c;
      }
    }

    &.features {
      &:before {
        background-color: #795548;
      }
    }

    &.mobileattendance {
      &:before {
        background-color: #0B968B;
      }
    }

    .accordion {
      &__icon {
        width: rem(40);
        height: rem(40);
        img {
          width: rem(40);
          height: rem(40);
        }
      }

      &__txt {
        padding-left: rem(8);
        width: calc(100% - rem(40));
        // display: none;

        // @include breakpoint(medium) {
        //   display: block;
        // }
      }

      &__title {
        font-size: rem(16);
        margin-bottom: rem(2);

        @include breakpoint(medium) {
          font-size: rem(12);
        }

        @include breakpoint(large) {
          font-size: rem(16);
        }
      }

      &__desc {
        font-size: rem(12);
        color: #78909c;
        display: none;

        @include breakpoint(large) {
          display: block;
        }
      }

      &__content {
        display: none;

        &.d-m-none {
          @include breakpoint-down(mobile) {
            display: none !important;
          }
        }

        img {
          width: 100%;
        }

        p {
          margin-top: rem(4);
          // font-size: rem(12);
        }
      }
    }
  }

  &__content {
    width: 100%;

    .content {
      &--img-container {
        margin-top: rem(8);
        overflow: hidden;
        width: 100%;
        height: rem(480);
        background: #ffffff;
        position: relative;
        // box-shadow: 0px 4px 18px 0px rgba(109, 109, 109, 0.1);
        // box-shadow: rem(4) rem(5) rem(16) rgba(0, 0, 0, 0.1);
        border: 1px solid #f3f3f3;
        border-radius: rem(16);
      }

      &--txt {
        margin-top: rem(24);

        p {
          font-size: rem(18);
        }
      }

      &--img {
        position: absolute;
        top: 0;
        left: 0;

        // img{
        //   box-shadow: rem(4) rem(5) rem(16) rgba(52, 147, 147, 1);
        // }

        &--01.img-payroll {
          top: -30px;
          left: -30px;
        }

        &--02.img-payroll {
          top: 30px;
          left: 320px;
        }

        &--01.img-payroll {
          top: -30px;
          left: -30px;
        }

        &--02.img-payroll {
          top: 30px;
          left: 320px;
        }

        &--01.img-leave {
          left: -80px;
          top: -100px;
          z-index: 1;
        }

        &--02.img-leave {
          left: 220px;
          top: -140px;
        }

        &--01.img-attendance {
          left: -50px;
          top: -40px;
          z-index: 1;
        }

        &--02.img-attendance {
          left: 320px;
          top: -100px;
        }

        &--01.img-mobile-attendance {
          left: 60px;
          top: -70px;
        }

        &--02.img-mobile-attendance {
          left: 360px;
          top: -210px;
        }

        &--01.img-meal {
          left: -30px;
          top: 90px;
        }

        &--02.img-meal {
          left: 380px;
          top: -100px;
        }

        &--01.img-claim {
          left: -50px;
          top: 40px;
        }

        &--02.img-claim {
          left: 290px;
          top: -280px;
        }

        &--01.img-award {
          left: -30px;
          top: -10px;
          z-index: 1;
        }

        &--02.img-award {
          left: 290px;
          top: -280px;
        }

        &--01.img-education {
          left: 0;
          top: 0;
        }

        &--02.img-education {
          left: 400px;
          top: -10px;
        }

        &--01.img-employeeprofile {
          top: 20px;
          left: 50%;
          transform: translate(-50%, 0%);
          // position: relative;

          img {
            // width: 100%;
            // max-width: 700px;
            // margin: auto;
            // display: block;
            width:340px;
          }
        }
      }
    }
  }
}

@keyframes underlineY {
  from {
    transform: scaleY(1);
  }
  to {
    transform: scaleY(0);
  }
}

// .accordion1 {
//   margin: 50px;
//   dt, dd {
//      padding: 10px;
//      border: 1px solid black;
//      border-bottom: 0;
//      &:last-of-type {
//        border-bottom: 1px solid black;
//      }
//      a {
//        display: block;
//        color: black;
//        font-weight: bold;
//      }
//   }
//  dd {
//     border-top: 0;
//     font-size: 12px;
//     &:last-of-type {
//       border-top: 1px solid white;
//       position: relative;
//       top: -1px;
//     }
//  }
// }
