html {
  font-size: 100%;
  box-sizing: border-box;
  scroll-behavior: smooth;
}

*,
*::before,
*::after {
  box-sizing: border-box;
}

body {
  margin: 0;
  padding: 0;
  font-family: var(--font-inter);
  color: #2f2f2f;
}

h1,
h2,
h3 {
  margin-top: 0;
}

a,
a:visited,
a:active {
  text-decoration: none;
}

:root {
  --color-primary: #00AB9D;
  --color-primary-dark: #00acc1;
  --color-primary-light: #64b5f6;
  --color-primary-text: #ffffff;
  --color-primary-text-dark: #b2ebf2;
  --color-primary-text-light: #ffffff;
  --color-accent: #ffc107;
  --color-accent-dark: #ffb300;
  --color-accent-light: #ffeb3b;
  --color-accent-text: #000000;
  --color-accent-text-dark: #000000;
  --color-accent-text-light: #000000;
  --color-background: #ffffff;
  --color-background-dark: #e0e0e0;
  --color-background-light: #ffffff;
  --color-background-text: #000000;
  --color-background-text-dark: #000000;
  --color-background-text-light: #000000;
  --color-background-secondary: #fafafa;
  --color-background-secondary-dark: #f5f5f5;
  --color-background-secondary-light: #ffffff;
  --color-background-secondary-text: #000000;
  --color-background-secondary-text-dark: #000000;
  --color-background-secondary-text-light: #000000;
  --color-background-tertiary: #fafafa;
  --color-background-tertiary-dark: #f5f5f5;
  --color-background-tertiary-light: #ffffff;
  --color-background-tertiary-text: #000000;
  --color-background-tertiary-text-dark: #000000;
  --color-background-tertiary-text-light: #000000;
  --color-background-quaternary: #fafafa;
  --color-background-quaternary-dark: #f5f5f5;
  --color-background-quaternary-light: #ffffff;
}

:root {
  --font-inter: "Inter", sans-serif;
}

.container {
  padding: 0 1.25rem;
  margin: 0 auto;
}
@media (min-width: 40em) {
  .container {
    padding: 0 2.5rem;
  }
}
@media (min-width: 71.875em) {
  .container {
    max-width: 80rem;
    padding: 0 2rem;
  }
}
.container.fluid {
  padding: 0;
}
.container.mw960 {
  max-width: 60rem;
}
p {
  line-height: 1.5;
}

a.href-link {
  color: var(--color-primary);
}
a.href-link:hover {
  color: #06897e;
}

.d-none {
  display: none;
}

@media (min-width: 40em) {
  .d-md-none {
    display: none;
  }
}

@media (min-width: 71.875em) {
  .d-lg-none {
    display: none;
  }
}

.d-block {
  display: block;
}

@media (min-width: 40em) {
  .d-md-block {
    display: block;
  }
}

@media (min-width: 71.875em) {
  .d-lg-block {
    display: block;
  }
}

.m-auto {
  margin: auto;
}

.img-responsive {
  display: none;
}
.img-responsive.show-mobile {
  display: block;
}
@media (min-width: 40em) {
  .img-responsive.show-mobile {
    display: none;
  }
}
@media (min-width: 40em) {
  .img-responsive.show-tablet {
    display: block;
  }
}
@media (min-width: 71.875em) {
  .img-responsive.show-tablet {
    display: none;
  }
}
@media (min-width: 71.875em) {
  .img-responsive.show-desktop {
    display: block;
  }
}

.position-relative {
  position: relative;
}

.btn-primary {
  display: inline-block;
  background-color: var(--color-primary);
  color: var(--color-primary-text-light);
  padding: 1rem 2rem;
  border-radius: 30px;
  transition: all 0.3s;
  box-shadow: 9px 9px 18px rgba(0, 171, 157, 0.2), -9px -9px 18px white;
}
.btn-primary:hover {
  background-color: #06897e;
}

.btn-highlight {
  display: inline-block;
  background-color: #64b99e;
  color: #2f2f2f;
  padding: 1rem 2rem;
  border-radius: 30px;
  transition: all 0.3s;
  box-shadow: 9px 9px 18px rgba(0, 171, 157, 0.2), -9px -9px 18px white;
}
.btn-highlight:hover {
  background-color: #4aa387;
}

.btn-cta {
  background: linear-gradient(90deg, #3b76ab, #4ca984);
  border-radius: 29px;
  padding: 0.5rem 1.5rem;
  border: none;
  outline: none;
  display: flex !important;
  align-items: center;
  cursor: pointer;
  position: relative;
  top: 1px;
  height: 50px;
  z-index: 10;
  box-shadow: 0 10px 13px 0 rgba(209, 211, 237, 0.39);
  font-weight: bold;
  transition: all 0.3s;
  color: #2f2f2f;
}
.btn-cta span {
  width: 24px;
  height: 24px;
  margin-right: 0.5rem;
}
.btn-cta:before {
  content: "";
  z-index: -1;
  top: 3px;
  left: 3px;
  position: absolute;
  background: #fff;
  width: calc(100% - 6px);
  height: calc(100% - 6px);
  border-radius: 30px;
}
.btn-cta:hover {
  color: #ffffff;
}
.btn-cta:hover:before {
  background: transparent;
}

.btn-cta-02 {
  background: linear-gradient(90deg, #3b76ab, #4ca984);
  border-radius: 29px;
  padding: 1rem 1.5rem;
  border: none;
  outline: none;
  display: inline-block;
  cursor: pointer;
  position: relative;
  top: 1px;
  z-index: 10;
  box-shadow: 0 0 #0000, 0 0 #0000, 0 0 #0000, 0 0 #0000, rgba(180, 0, 9, 0.2);
  font-weight: bold;
  transition: all 0.3s;
  color: #ffffff;
}
.btn-cta-02:before {
  content: "";
  z-index: -1;
  top: 3px;
  left: 3px;
  position: absolute;
  background: transparent;
  width: calc(100% - 6px);
  height: calc(100% - 6px);
  border-radius: 30px;
}
.btn-cta-02:hover:before {
  background: linear-gradient(90deg, #2c679b, #358867);
}

.btn-cta-03 {
  background: linear-gradient(90deg, #fff, #fff);
  border-radius: 29px;
  padding: 1rem 1.5rem;
  border: none;
  outline: none;
  display: flex;
  cursor: pointer;
  position: relative;
  top: 1px;
  z-index: 10;
  box-shadow: 0 0 #0000, 0 0 #0000, 0 0 #0000, 0 0 #0000, rgba(180, 0, 9, 0.2);
  font-weight: bold;
  transition: all 0.3s;
  color: #2e2e2e;
}
.btn-cta-03:before {
  content: "";
  z-index: -1;
  top: 3px;
  left: 3px;
  position: absolute;
  background: transparent;
  width: calc(100% - 6px);
  height: calc(100% - 6px);
  border-radius: 30px;
}
.btn-cta-03:hover:before {
  background: rgba(84, 84, 84, 0.1);
}
.btn-cta-03 span {
  width: 24px;
  height: 24px;
  margin-right: 0.5rem;
}

.overflow-x-hidden {
  overflow-x: hidden;
}

.whywork__row {
  display: flex;
  gap: 1rem;
  justify-content: center;
  flex-wrap: wrap;
}
.whywork__col {
  width: 100%;
  margin: auto;
  transform: scale(1);
  transition: all 0.4s;
}
@media (min-width: 40em) {
  .whywork__col {
    max-width: 20rem;
  }
}
.whywork__animate {
  border-radius: 50%;
  display: flex;
  justify-content: center;
  box-shadow: 9px 9px 18px rgba(0, 171, 157, 0.2), -9px -9px 18px white;
  margin: auto;
  position: relative;
  background-color: #eef4f5;
  width: 280px;
  height: 280px;
}
@media (min-width: 40em) {
  .whywork__animate {
    width: 320px;
    height: 320px;
  }
}
.whywork__content {
  padding: 1rem 0rem;
  margin-top: 1rem;
  text-align: center;
  z-index: 9;
  transition: all 0.3s;
  text-shadow: 6px 11px 24px #ffffff;
}
.whywork__content h4 {
  font-size: 1.125rem;
  margin-bottom: 0.75rem;
  margin-top: 0;
}
.whywork__content p {
  margin: 0;
}

@keyframes bounce {
  0%, 20%, 50%, 80%, 100% {
    transform: translateY(0);
  }
  40% {
    transform: translateY(-30px);
  }
  60% {
    transform: translateY(-15px);
  }
}
section {
  padding: 2.5rem 0;
  scroll-margin-top: 3.75rem;
}
@media (min-width: 71.875em) {
  section {
    padding: 5rem 0;
    scroll-margin-top: 5rem;
  }
}
section.p-0 {
  padding: 0;
}

.section__title {
  font-size: 1.5rem;
  margin-bottom: 1.25rem;
  text-align: center;
}
@media (min-width: 40em) {
  .section__title {
    margin-bottom: 2.5rem;
    font-size: 2rem;
  }
}

.figuredata__row {
  justify-content: center;
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(16.875rem, 1fr));
  width: 100%;
  grid-gap: 1.5rem;
}
@media (min-width: 40em) {
  .figuredata__row {
    grid-gap: 2rem;
  }
}
.figuredata__col {
  display: flex;
  flex-direction: column;
  padding: 1rem 1.5rem;
  align-items: center;
  justify-content: center;
  box-shadow: 4px 4px 9px rgba(0, 0, 0, 0.2), -4px -4px 9px rgba(255, 255, 255, 0.2);
  border-radius: 16px;
  text-align: center;
  width: 100%;
  margin: auto;
  max-width: 18.75rem;
  background-color: #eef4f5;
}
@media (min-width: 40em) {
  .figuredata__col {
    max-width: none;
  }
}
.figuredata__figure {
  font-weight: bold;
  font-size: 1.75rem;
  color: #2f2f2f;
}
@media (min-width: 40em) {
  .figuredata__figure {
    font-size: 2.5rem;
  }
}
.figuredata__txt {
  font-size: 0.875rem;
  padding-top: 0.25rem;
  margin: 0;
  color: #243036;
}
@media (min-width: 40em) {
  .figuredata__txt {
    padding-top: 0.5rem;
    font-size: 1rem;
  }
}

.numbers__window {
  display: inline-block;
  overflow: hidden;
  width: 26px;
  height: 1em;
}
.numbers__window__digit {
  font: inherit;
  word-break: break-all;
  display: block;
  width: 0;
  padding: 0 0.52em 0 0;
  margin: 0 auto;
  overflow: inherit;
  animation: counting 0.4s steps(10) forwards infinite;
}
.numbers__window__digit:before {
  content: attr(data-fake);
  display: inline-block;
  width: 100%;
  height: auto;
}
.numbers__window__digit--1 {
  animation-iteration-count: 3;
}
.numbers__window__digit--2 {
  animation-iteration-count: 6;
}
.numbers__window__digit--3 {
  animation-iteration-count: 9;
}
.numbers__window__digit--4 {
  animation-iteration-count: 12;
}
.numbers__window__digit--5 {
  animation-iteration-count: 15;
}
.numbers__window__digit--6 {
  animation-iteration-count: 18;
}

@keyframes counting {
  100% {
    transform: translate3d(0, -10em, 0);
  }
}
.products__bg {
  background: linear-gradient(to top, #edfaf9, #ffffff);
}
.products__row {
  display: flex;
  gap: 2rem;
  align-items: center;
  flex-wrap: wrap;
}
@media (min-width: 40em) {
  .products__row {
    flex-wrap: nowrap;
  }
}
.products__thumbnail {
  width: 100%;
}
@media (min-width: 40em) {
  .products__thumbnail {
    width: 50%;
  }
}
.products__content {
  width: 100%;
}
@media (min-width: 40em) {
  .products__content {
    width: 50%;
  }
}
.products__content h3 {
  font-size: 1.5rem;
  font-weight: bold;
  margin-bottom: 1rem;
}
.products__content p {
  margin: 1rem 0;
}
.products__divider {
  height: 4px;
  background-color: #99ddd8;
  width: 80px;
  border-radius: 4px;
}

.contact__bg {
  background-color: #f2f9ff;
}
.contact__grid {
  display: grid;
  grid-gap: 1rem;
  grid-template-columns: 100%;
  grid-template-areas: "header" "highlight" "form";
}
@media (min-width: 40em) {
  .contact__grid {
    grid-gap: 2rem;
  }
}
@media (min-width: 71.875em) {
  .contact__grid {
    grid-template-columns: 33% calc(67% - 2rem);
    grid-template-areas: "highlight header" "highlight form";
  }
}
.contact__highlight {
  grid-area: highlight;
  margin: 0 auto;
}
.contact__highlight--img {
  margin-bottom: 1rem;
}
.contact__highlight--img img {
  max-width: 24rem;
}
.contact__highlight--detail .detail__row {
  display: flex;
  align-items: center;
  margin-bottom: 0.5rem;
  justify-content: center;
}
@media (min-width: 71.875em) {
  .contact__highlight--detail .detail__row {
    justify-content: flex-start;
  }
}
.contact__highlight--detail .detail__row.pdpa-row {
  margin-top: 1.5rem;
  font-size: 80%;
}
.contact__highlight--detail .detail__icon {
  width: 1.5rem;
  margin-right: 0.5rem;
}
.contact__highlight--detail .detail__icon img {
  width: 1.5rem;
}
.contact__highlight--detail .detail__txt {
  color: #78909c;
}
.contact__highlight--detail .detail__txt a {
  text-decoration: underline;
  color: var(--color-primary);
}
.contact__highlight--detail .detail__txt a:hover {
  color: #06897e;
}
.contact__header {
  grid-area: header;
}
.contact__header h2 {
  margin-bottom: 1rem;
  font-size: 1.5rem;
  text-align: center;
}
@media (min-width: 40em) {
  .contact__header h2 {
    font-size: 2rem;
  }
}
@media (min-width: 71.875em) {
  .contact__header h2 {
    text-align: left;
  }
}
.contact__header p {
  margin: 0;
}
.contact__success {
  background: #f2f9ff;
  position: absolute;
  height: 102%;
  width: 100%;
  top: 0;
  left: 0;
  z-index: 99;
  display: none;
}
.contact__success.sent-success {
  display: block;
}
.contact__success #contactSuccess {
  width: 50%;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 100;
}
.contact__form {
  grid-area: form;
  position: relative;
}
.contact__form .form__row {
  display: flex;
  flex-wrap: wrap;
}
@media (min-width: 40em) {
  .contact__form .form__row {
    flex-wrap: nowrap;
    margin: -1rem;
  }
}
.contact__form .form__col50per {
  width: 100%;
}
@media (min-width: 40em) {
  .contact__form .form__col50per {
    width: 50%;
    margin: 1rem;
  }
}
.contact__form .form__col100per {
  width: 100%;
}
@media (min-width: 40em) {
  .contact__form .form__col100per {
    margin: 1rem;
  }
}
.contact__form .form__group {
  margin-bottom: 1rem;
  width: 100%;
  font-size: 0.75rem;
  position: relative;
}
@media (min-width: 40em) {
  .contact__form .form__group {
    font-size: 1rem;
    margin-bottom: 0.5rem;
  }
}
.contact__form .form__group label {
  display: inline-block;
  margin-bottom: 0.5rem;
  font-weight: bold;
}
.contact__form .form__group label.form-check-label {
  font-weight: normal;
  margin-bottom: 0;
}
.contact__form .form__group .form-control {
  display: block;
  width: 100%;
  padding: 0.375rem 0.75rem;
  font-size: 0.75rem;
  line-height: 1.5;
  color: #495057;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid #ced4da;
  border-radius: 0.25rem;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  font-family: var(--font-inter);
}
@media (min-width: 40em) {
  .contact__form .form__group .form-control {
    font-size: 1rem;
  }
}
.contact__form .form__group .form-control:focus {
  color: #495057;
  background-color: #fff;
  border-color: #00ab9d;
  outline: 0;
  box-shadow: 0 0 0 0.2rem rgba(0, 171, 157, 0.25);
}
.contact__form .form__group textarea {
  overflow: auto;
  resize: vertical;
}
.contact__form .form__group input {
  overflow: visible;
}
.contact__form .form__group .form-check {
  display: flex;
  align-items: flex-start;
  gap: 0.5rem;
}
.contact__form .form__validate {
  display: none;
  color: #b12020;
  font-size: 0.625rem;
  margin-top: 0.25rem;
}
.contact__form .form__validate.has-error {
  display: block;
}
.contact__form .form-submit {
  margin-top: 0.75rem;
}

.grecaptcha-badge {
  opacity: 0;
}
.grecaptcha-badge.show {
  opacity: 1;
  z-index: 999;
}

/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type=number] {
  -moz-appearance: textfield;
}

.table-striped > tbody > tr:nth-of-type(odd) > * {
  --bs-table-accent-bg: #f2f9ff;
}

#tbodyContact.first-load tr {
  display: none;
}
#tbodyContact.first-load tr:nth-child(-n+9) {
  display: table-row;
}

#contact01 {
  max-width: 500px;
}

.landing__bg {
  background-image: url("../images/bg-landing.png");
  background-size: 100%;
  background-position: center bottom;
  background-repeat: no-repeat;
}
.landing__bgcolor {
  background-image: linear-gradient(to top, #f4f7fc, #ffffff);
  height: 200%;
  position: absolute;
  bottom: 0;
  width: 100%;
  z-index: -1;
}
.landing__row {
  display: flex;
  align-items: center;
}
.landing__content {
  width: 40%;
}
.landing__content .content__header {
  font-style: italic;
  font-size: 1.25rem;
  color: #78909c;
  margin-bottom: 1rem;
}
.landing__content .content__liner {
  font-size: 2rem;
  margin-bottom: 1rem;
}
.landing__content .content__typewriter {
  font-size: 3.75rem;
  margin-bottom: 2rem;
  font-weight: bold;
}
.landing__img {
  width: 60%;
}
.landing__img img {
  max-width: 42.1875rem;
  display: block;
  margin: auto;
}

.sublanding__bg {
  background: linear-gradient(to bottom, #edfaf9, rgba(255, 255, 255, 0));
}
@media (min-width: 40em) {
  .sublanding__bg {
    padding-bottom: 200px;
  }
}
.sublanding__bg--gradient:before {
  content: "";
  width: 100%;
  position: absolute;
  z-index: -2;
  top: 0;
  left: 0px;
  right: 0px;
  height: 100%;
  background: linear-gradient(90deg, #3b76ab, #4ca984);
}
.sublanding__bg--gradient:after {
  content: "";
  width: 100%;
  position: absolute;
  z-index: -2;
  top: 0;
  left: 0px;
  right: 0px;
  height: 100%;
  background: linear-gradient(180deg, rgba(255, 255, 255, 0.9) 50%, rgba(255, 255, 255, 0.2) 100%);
}
.sublanding__bg--payroll {
  background: linear-gradient(to top, rgba(0, 171, 157, 0.2), rgba(255, 255, 255, 0.1));
}
.sublanding__bg--leave {
  background: linear-gradient(to top, rgba(33, 150, 243, 0.2), rgba(255, 255, 255, 0.1));
}
.sublanding__bg--attendance {
  background: linear-gradient(to top, rgba(239, 108, 0, 0.2), rgba(255, 255, 255, 0.1));
}
.sublanding__bg--mobileattendance {
  background: linear-gradient(to top, rgba(4, 137, 126, 0.2), rgba(255, 255, 255, 0.1));
}
.sublanding__bg--claim {
  background: linear-gradient(to top, rgba(239, 83, 80, 0.2), rgba(255, 255, 255, 0.1));
}
.sublanding__bg--employeeprofile {
  background: linear-gradient(to top, rgba(120, 144, 156, 0.2), rgba(255, 255, 255, 0.1));
}
.sublanding__bg--features {
  background: linear-gradient(to top, rgba(121, 85, 72, 0.2), rgba(255, 255, 255, 0.1));
}
.sublanding__bgcolor {
  background-image: linear-gradient(to top, #f4f7fc, #ffffff);
  height: 200%;
  position: absolute;
  bottom: 0;
  width: 100%;
  z-index: -1;
}
.sublanding__pagebtn {
  background: rgba(255, 255, 255, 0);
  backdrop-filter: blur(24px);
  position: absolute;
  width: auto;
  display: flex;
  align-items: center;
  color: #00ab9d;
  text-align: left;
  z-index: 3;
  transition: 0.3s all;
}
@media (min-width: 40em) {
  .sublanding__pagebtn {
    position: absolute;
    bottom: 180px;
  }
}
.sublanding__pagebtn__container {
  display: flex;
  align-items: center;
  flex-wrap: nowrap;
  width: calc(100% + 40px);
  margin-left: -20px;
  margin-bottom: -12px;
}
.sublanding__pagebtn__content {
  display: none;
  padding-left: 4px;
  padding-right: 4px;
  align-items: center;
  opacity: 0;
  transition: 0.3s all;
}
.sublanding__pagebtn__txt {
  color: #2f2f2f;
  margin-left: 12px;
  word-break: keep-all;
}
@media (min-width: 40em) {
  .sublanding__pagebtn {
    background: rgba(255, 255, 255, 0.4);
  }
}
.sublanding__pagebtn:hover {
  background: rgba(255, 255, 255, 0.9);
}
@media (min-width: 40em) {
  .sublanding__pagebtn:hover .sublanding__pagebtn__icon {
    width: 100%;
  }
  .sublanding__pagebtn:hover .sublanding__pagebtn__content {
    display: flex;
    opacity: 1;
  }
}
@media (min-width: 40em) {
  .sublanding__pagebtn {
    width: auto;
  }
}
.sublanding__pagebtn__icon {
  width: 40px;
  height: 40px;
  display: flex;
  align-items: center;
  transition: 0.3s all;
}
.sublanding__pagebtn svg {
  color: #00ab9d;
  width: 40px;
}
.sublanding__pagebtn--prev {
  left: 0;
  padding: 8px;
  margin-right: auto;
}
.sublanding__pagebtn--next {
  right: 0;
  padding: 8px;
}
.sublanding__row {
  display: flex;
  gap: 2rem;
}
@media (max-width: 71.8125em) {
  .sublanding__row {
    flex-direction: column-reverse;
  }
}
.sublanding__headline {
  width: 100%;
  padding-top: 18.125rem;
}
@media (min-width: 40em) {
  .sublanding__headline {
    padding-top: 15rem;
    padding-bottom: 0rem;
  }
}
@media (min-width: 71.875em) {
  .sublanding__headline {
    width: 40%;
    padding-top: 18.75rem;
    padding-bottom: 6.25rem;
  }
}
.sublanding__subheadline {
  margin: auto;
}
.sublanding__subheadline .product-link {
  font-weight: normal;
  text-transform: uppercase;
  font-size: 1rem;
  color: #657085;
  letter-spacing: 3px;
  padding-bottom: 6px;
  padding-right: 12px;
  position: relative;
}
.sublanding__subheadline .product-link::after {
  background: #00ab9d;
  border-radius: 10px;
  bottom: 0;
  content: "";
  display: block;
  height: 2px;
  position: absolute;
  transition: width 0.5s ease 0s, left 0.5s ease 0s;
  width: 0;
  left: 0;
}
.sublanding__subheadline .product-link:hover::after {
  width: 100%;
}
.sublanding__title h1 {
  margin-bottom: 1rem;
  font-size: 1.625rem;
}
@media (min-width: 40em) {
  .sublanding__title h1 {
    font-size: 2.25rem;
  }
}
.sublanding__title.w_icon {
  display: flex;
  gap: 16px;
  align-items: center;
  margin-top: 24px;
}
.sublanding__title.w_icon h1 {
  margin-bottom: 0;
}
.sublanding__divider {
  background-color: #00ab9d;
  height: 10px;
  width: 90px;
  margin-bottom: 1.5rem;
  border-radius: 40px;
}
.sublanding__content {
  max-width: 56.25rem;
}
.sublanding__content p {
  font-size: 1rem;
}
@media (min-width: 40em) {
  .sublanding__content p {
    font-size: 1.25rem;
  }
}
.sublanding__app {
  display: flex;
  padding-top: 0.5rem;
  padding-bottom: 1.5rem;
  width: 100%;
  gap: 1rem;
}
.sublanding__app-btn img {
  height: 40px;
}

.phones__bg {
  background-image: url("../images/test-ss-01.png");
  background-position: center center;
  background-size: cover;
  height: 100%;
  left: 490px;
  position: absolute;
  top: -70px;
  width: 100%;
  opacity: 0.6;
}

.phone-screen-wrap {
  position: relative;
  width: auto;
}
@media (min-width: 40em) {
  .phone-screen-wrap {
    box-shadow: rgba(152, 163, 207, 0.3) 0px 20px 34px;
    margin-top: -180px;
    background: rgba(255, 255, 255, 0.5);
    margin-bottom: 60px;
    border-radius: 16px;
    padding-bottom: 6px;
    backdrop-filter: blur(12px);
  }
}
@media (min-width: 71.875em) {
  .phone-screen-wrap {
    margin-top: -240px;
  }
}
.phone-screen-wrap .pattern__style {
  position: absolute;
  z-index: 2;
}
.phone-screen-wrap .pattern__style img {
  width: 100%;
}
.phone-screen-wrap .pattern__style--01 {
  top: -8%;
  left: -4%;
  animation-duration: 11s;
  width: 100px;
  height: 100px;
}
.phone-screen-wrap .pattern__style--02 {
  left: -2%;
  top: 38%;
  animation-duration: 12s;
  width: 100px;
  height: 100px;
}
.phone-screen-wrap .pattern__style--03 {
  top: 21%;
  right: -5%;
  width: 90px;
  height: 90px;
  animation-duration: 13s;
}
.phone-screen-wrap .pattern__style--04 {
  top: 12%;
  left: -7%;
  width: 90px;
  animation-duration: 14s;
}
.phone-screen-wrap .pattern__style--05 {
  bottom: 25%;
  right: 3%;
  width: 100px;
  animation-duration: 15s;
}
.phone-screen-wrap .pattern__style--06 {
  bottom: -3%;
  right: 11%;
  width: 100px;
  animation-duration: 18s;
}

.phone-screen {
  display: flex;
  justify-content: center;
  margin-left: auto;
  margin-top: -60px;
}
.phone-screen__coming-soon {
  width: auto;
  display: inline-block;
  bottom: 15%;
  right: 10%;
  position: absolute;
  padding: 6px 12px;
  background: #db0011;
  transform: rotate(-35deg);
  color: #ffffff;
  font-size: 14px;
  line-height: 100%;
}
.phone-screen__present {
  max-width: 360px;
  display: relative;
}
.phone-screen__present.d-m-none {
  display: none;
}
@media (min-width: 40em) {
  .phone-screen__present.d-m-none {
    display: block;
  }
}

.phone {
  height: 17rem;
  transform: scale(0.48) rotate(-30deg);
  position: absolute;
  width: 100%;
}
@media (min-width: 40em) {
  .phone {
    transform: scale(1) rotate(0deg);
    position: relative;
  }
}
@media (min-width: 71.875em) {
  .phone {
    position: absolute;
    left: 60px;
    height: 14rem;
  }
}
.phone__present {
  height: auto;
  position: absolute;
  z-index: 1;
  pointer-events: none;
  width: 530px;
}
.phone__present--01 {
  left: -340px;
  top: -610px;
}
.phone__present--02 {
  left: 20px;
  top: -740px;
}
.phone__present--03 {
  left: -165px;
  top: -240px;
}
.phone__present--05 {
  left: 150px;
  top: -230px;
}
.phone__present--06 {
  left: 500px;
  top: -290px;
}
.phone__present--07 {
  left: 320px;
  top: 215px;
}
@media (min-width: 40em) {
  .phone__present--01 {
    left: -290px;
    top: -420px;
  }
  .phone__present--02 {
    top: -610px;
    left: 90px;
  }
  .phone__present--03 {
    left: -90px;
    top: -120px;
  }
  .phone__present--04 {
    left: 330px;
    top: -390px;
  }
  .phone__present--05 {
    left: 260px;
    top: -200px;
  }
  .phone__present--06 {
    left: 690px;
    top: -510px;
    display: block;
  }
  .phone__present--07 {
    left: 510px;
    top: -10px;
  }
}
.phone__present img {
  width: 100%;
}

.group-feature__grid {
  display: flex;
  grid-gap: 2.5rem 2rem;
  justify-content: center;
  flex-wrap: wrap;
}
.group-feature__item {
  text-align: center;
  width: 100%;
  max-width: 380px;
  transform: scale(1);
  transition: 0.3s all;
}
@media (min-width: 71.875em) {
  .group-feature__item--halfw {
    max-width: 500px;
  }
}
.group-feature__item:hover .group-feature__content {
  --_p: 0%;
}
.group-feature__item:hover .group-feature__icon {
  margin-left: 30px;
}
.group-feature__item:hover .group-feature__icon::before {
  content: "";
  display: block;
  background: #ffffff;
  width: 60px;
  height: 60px;
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  border-radius: 100%;
  z-index: -2;
  animation: spin 1s linear infinite;
  box-shadow: 0px 2px 5px #00ab9d;
}
.group-feature__icon {
  width: 60px;
  height: 60px;
  border-radius: 50%;
  background-color: #ffffff;
  border: 4px solid transparent;
  margin: 0 auto -18px 40px;
  z-index: 2;
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  box-shadow: 0px 0px 4px 0px #00000040;
  transition: 0.3s all;
}
.group-feature__icon-wrap {
  z-index: 2;
  position: relative;
}
.group-feature__content {
  border-top: 4px solid #00ab9d;
  border-radius: 4px;
  background: linear-gradient(90deg, #effbf9 50%, #ffffff 0) var(--_p, 100%)/200% no-repeat;
  padding: 24px 16px 16px;
  height: calc(100% - 32px);
  position: relative;
  box-shadow: 0px 4px 4px 0px #0000001e;
  transition: 0.5s all;
}
.group-feature__content p {
  padding: 0;
  margin: 0;
  color: #657085;
}
.group-feature__title {
  font-size: 20px;
  font-weight: bold;
  margin-bottom: 8px;
  color: #2f2f2f;
}

.key-feature__container {
  display: flex;
}
.key-feature__content {
  border-radius: 8px;
  padding: 8px 24px;
  margin: auto;
  text-align: center;
  width: 100%;
  margin-bottom: 40px;
  font-size: 20px;
  font-weight: bold;
}
.key-feature__content .txt-sm {
  font-size: 70%;
  opacity: 0.7;
  display: block;
  font-weight: normal;
}
.key-feature__content.bg--payroll {
  background: #fff;
}
.key-feature__content.bg--leave {
  background: linear-gradient(to top, rgba(33, 150, 243, 0.2), rgba(255, 255, 255, 0.1));
}
.key-feature__content.bg--attendance {
  background: linear-gradient(to top, rgba(239, 108, 0, 0.2), rgba(255, 255, 255, 0.1));
}
.key-feature__content.bg--mobileattendance {
  background: linear-gradient(to top, rgba(239, 108, 0, 0.2), rgba(255, 255, 255, 0.1));
}
.key-feature__content.bg--claim {
  background: linear-gradient(to top, rgba(239, 83, 80, 0.2), rgba(255, 255, 255, 0.1));
}
.key-feature__content.bg--employeeprofile {
  background: linear-gradient(to top, rgba(120, 144, 156, 0.2), rgba(255, 255, 255, 0.1));
}
.key-feature__content.bg--features {
  background: linear-gradient(to top, rgba(121, 85, 72, 0.2), rgba(255, 255, 255, 0.1));
}
.key-feature__icon {
  border-radius: 50%;
  box-shadow: 0.25rem 0.3125rem 1rem rgba(52, 147, 147, 0.5);
  width: 180px;
  height: 180px;
  display: flex;
  justify-content: center;
  align-items: center;
  background: #ffffff;
  z-index: 2;
}
.key-feature__icon img {
  width: 150px;
}

@keyframes spin {
  from {
    transform: translate(-50%, -50%) rotate(0);
  }
  to {
    transform: translate(-50%, -50%) rotate(360deg);
  }
}
/**
 * ==============================================
 * Dot Flashing
 * ==============================================
 */
.snippet {
  display: flex;
  justify-content: center;
}

.dot-flashing {
  position: relative;
  width: 10px;
  height: 10px;
  border-radius: 5px;
  background-color: #00ab9d;
  color: #00ab9d;
  animation: dotFlashing 1s infinite linear alternate;
  animation-delay: 0.5s;
}

.dot-flashing::before,
.dot-flashing::after {
  content: "";
  display: inline-block;
  position: absolute;
  top: 0;
}

.dot-flashing::before {
  left: -15px;
  width: 10px;
  height: 10px;
  border-radius: 5px;
  background-color: #00ab9d;
  color: #00ab9d;
  animation: dotFlashing 1s infinite alternate;
  animation-delay: 0s;
}

.dot-flashing::after {
  left: 15px;
  width: 10px;
  height: 10px;
  border-radius: 5px;
  background-color: #00ab9d;
  color: #00ab9d;
  animation: dotFlashing 1s infinite alternate;
  animation-delay: 1s;
}

@keyframes dotFlashing {
  0% {
    background-color: #00ab9d;
  }
  50%, 100% {
    background-color: #d3edeb;
  }
}
.features__grid {
  display: flex;
  grid-gap: 2rem;
  justify-content: center;
  flex-wrap: wrap;
}
.features__item {
  text-align: center;
  padding: 1rem;
  max-width: 380px;
}
.features__item .item__icon {
  width: 5rem;
  height: 5rem;
  margin-bottom: 1rem;
  margin-left: auto;
  margin-right: auto;
  background: linear-gradient(90deg, #3b76ab, #4ca984);
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  box-shadow: 0.25rem 0.3125rem 1rem rgba(52, 147, 147, 0.5);
}
.features__item .item__icon img {
  width: 2.5rem;
  height: 2.5rem;
}
.features__item .item__title h4 {
  font-size: 1.125rem;
  font-weight: bold;
  margin-bottom: 0.5rem;
  margin-top: 0;
}
@media (min-width: 40em) {
  .features__item .item__title h4 {
    font-size: 1.5rem;
  }
}
.features__item .item__desc p {
  margin: 0;
}

.screenshot__bg {
  background-image: url("../images/thumbnail-app-bg-01.jpg");
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center center;
  background-attachment: fixed;
}
.screenshot__height {
  height: 18.75rem;
}

.hero__container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
.hero__bg {
  display: flex;
  align-items: center;
  justify-content: center;
}
@media (min-width: 71.875em) {
  .hero__bg {
    height: 100vh;
  }
}
.hero__bg:before {
  content: "";
  width: 100%;
  position: absolute;
  z-index: -2;
  top: 0;
  left: 0px;
  right: 0px;
  height: calc(100vh + 150px);
  background: linear-gradient(90deg, #3b76ab, #4ca984);
}
.hero__bg:after {
  content: "";
  width: 100%;
  position: absolute;
  z-index: -2;
  top: 0;
  left: 0px;
  right: 0px;
  height: calc(100vh + 150px);
  background: linear-gradient(180deg, rgba(255, 255, 255, 0.9) 50%, rgba(255, 255, 255, 0.2) 100%);
}
.hero__row {
  display: flex;
  margin-bottom: 2.5rem;
}
@media (min-width: 40em) {
  .hero__row {
    margin-bottom: 5rem;
  }
}
.hero__content {
  text-align: left;
  line-height: 1.3;
}
.hero__content .content__header {
  font-style: italic;
  font-size: 1rem;
  color: #78909c;
  margin-bottom: 1rem;
}
@media (min-width: 40em) {
  .hero__content .content__header {
    font-size: 1.25rem;
  }
}
.hero__content .content__liner {
  font-size: 2rem;
  margin-bottom: 1rem;
  font-weight: bold;
}
@media (min-width: 40em) {
  .hero__content .content__liner {
    font-size: 3.75rem;
  }
}
.hero__content .content__liner span.highlight {
  background: linear-gradient(90deg, #2d4e8c, #3b76ab, #4ca984);
  -webkit-background-clip: text;
  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: rgba(255, 255, 255, 0.001);
  letter-spacing: -2.1px;
  white-space: nowrap;
}
.hero__content .content__typewriter {
  font-size: 3rem;
  font-weight: bold;
  display: block;
}
@media (min-width: 40em) {
  .hero__content .content__typewriter {
    font-size: 5.625rem;
  }
}
@media (min-width: 71.875em) {
  .hero__content .content__typewriter {
    display: inline;
  }
}
.hero__img {
  width: 40%;
}

.shape__animate {
  position: absolute;
  z-index: -1;
}
.shape__animate--01 {
  right: 130px;
  top: 170px;
  animation-duration: 11s;
}
.shape__animate--02 {
  left: 79px;
  top: 31px;
  animation-duration: 12s;
}
.shape__animate--03 {
  left: 421px;
  top: 399px;
  animation-duration: 13s;
}
.shape__animate--04 {
  right: 407px;
  top: 601px;
  animation-duration: 14s;
}
.shape__animate--05 {
  left: 530px;
  top: 607px;
  animation-duration: 15s;
}
.shape__animate--06 {
  left: 200px;
  top: 190px;
  animation-duration: 16s;
}
.shape__animate--07 {
  left: 432px;
  top: 67px;
  animation-duration: 17s;
}
.shape__animate--08 {
  left: 124px;
  top: 542px;
  animation-duration: 18;
}
.shape__animate--09 {
  right: 544px;
  top: 23px;
  animation-duration: 19s;
}
.shape__animate--10 {
  right: 305px;
  top: 332px;
  animation-duration: 20s;
}

.shapeAnimate {
  animation-iteration-count: infinite;
  animation-name: shapeAnimation;
  animation-timing-function: ease-in-out;
  animation-duration: 3s;
}
.shapeAnimate:nth-child(1) {
  animation-duration: 2s;
}
.shapeAnimate:nth-child(2) {
  animation-duration: 3s;
}
.shapeAnimate:nth-child(3) {
  animation-duration: 4s;
}
.shapeAnimate:nth-child(4) {
  animation-duration: 5s;
}
.shapeAnimate:nth-child(5) {
  animation-duration: 6s;
}

@keyframes shapeAnimation {
  0%, 100% {
    transform: scale(1);
  }
  50% {
    transform: scale(0.8);
  }
}
.bounceUpFloatBase {
  animation: bounceUp 1s, float 4s 1s infinite;
  animation-fill-mode: forwards;
  animation-timing-function: ease-in-out, ease-in-out;
  animation-delay: 0.2s, 0.27s;
  animation-duration: 0.7s, 8s;
}

@keyframes bounceUp {
  0% {
    transform: translateY(500px);
    opacity: 0;
  }
  60% {
    transform: translateY(-15px);
  }
  to {
    transform: translateY(0);
    opacity: 1;
  }
}
@keyframes float {
  0% {
    transform: translatey(0);
  }
  50% {
    transform: translatey(50px);
  }
  to {
    transform: translatey(0);
  }
}
.showcase__bg {
  background-image: url("../images/thumbnail-app-big.jpg");
  background-repeat: no-repeat;
  background-position: center center;
  background-attachment: fixed;
  background-size: cover;
  z-index: 1;
}
.showcase__bg--color-top {
  background: linear-gradient(to top, #edfaf9, rgba(255, 255, 255, 0.6), #edfaf9);
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: -1;
}
.showcase__row {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  padding-bottom: 2rem;
}
@media (min-width: 40em) {
  .showcase__row {
    flex-wrap: nowrap;
    padding-bottom: 0;
    gap: 2rem;
    flex-direction: row;
  }
}
.showcase__app {
  display: flex;
  padding-top: 2rem;
  padding-bottom: 1.5rem;
  width: 100%;
  justify-content: center;
  gap: 1rem;
}
.showcase__app-btn img {
  height: 40px;
}
.showcase__thumbnail {
  width: 100%;
  display: flex;
  align-items: flex-end;
  justify-content: center;
}
@media (min-width: 40em) {
  .showcase__thumbnail {
    width: 50%;
  }
}
.showcase__thumbnail .bg-thumbnail {
  background-image: url("../images/product-thumbnail-01-desktop.png");
  background-repeat: no-repeat;
  background-position: right bottom;
  background-size: cover;
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
}
.showcase__thumbnail .bg-extend {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: linear-gradient(to top, rgba(237, 250, 249, 0.9) 50%, rgba(255, 255, 255, 0.2) 100%);
  z-index: 1;
}
.showcase__thumbnail .mobile-ss {
  width: 100%;
  max-width: 460px;
  margin: auto;
  z-index: 2;
}
.showcase__thumbnail .thumbnail__pattern .pattern__style {
  position: absolute;
  z-index: 2;
}
.showcase__thumbnail .thumbnail__pattern .pattern__style img {
  width: 100%;
}
.showcase__thumbnail .thumbnail__pattern .pattern__style--01 {
  bottom: 12%;
  left: 5%;
  animation-duration: 11s;
  width: 100px;
  height: 100px;
}
.showcase__thumbnail .thumbnail__pattern .pattern__style--02 {
  left: -2%;
  top: 38%;
  animation-duration: 12s;
  width: 100px;
  height: 100px;
}
.showcase__thumbnail .thumbnail__pattern .pattern__style--03 {
  top: 21%;
  right: -5%;
  width: 90px;
  height: 90px;
  animation-duration: 13s;
}
.showcase__thumbnail .thumbnail__pattern .pattern__style--04 {
  top: 12%;
  left: -7%;
  width: 90px;
  animation-duration: 14s;
}
.showcase__thumbnail .thumbnail__pattern .pattern__style--05 {
  bottom: 25%;
  right: 3%;
  width: 100px;
  animation-duration: 15s;
}
.showcase__thumbnail .thumbnail__pattern .pattern__style--06 {
  bottom: -3%;
  right: 11%;
  width: 100px;
  animation-duration: 18s;
}
.showcase__content {
  width: 100%;
  padding: 0.75rem;
  background: rgba(255, 255, 255, 0.4);
  backdrop-filter: blur(4px);
}
@media (min-width: 40em) {
  .showcase__content {
    width: 50%;
    padding: 1.5rem;
  }
}
.showcase__content h3 {
  font-size: 1.5rem;
  font-weight: bold;
  margin-bottom: 1rem;
}
.showcase__content p {
  margin: 1rem 0;
}
.showcase__divider {
  height: 4px;
  background-color: #99ddd8;
  width: 80px;
  border-radius: 4px;
}

.floatBase {
  animation-fill-mode: forwards;
  animation-iteration-count: infinite;
  animation-name: float2;
  animation-timing-function: ease-in-out;
}

@keyframes float2 {
  0% {
    transform: translatey(0);
  }
  50% {
    transform: translatey(20px);
  }
  to {
    transform: translatey(0);
  }
}
.usage__row {
  display: flex;
  margin: -1rem;
}
.usage__col {
  width: 100%;
  margin: 1rem;
}
@media (min-width: 40em) {
  .usage__col {
    width: 30%;
  }
}
.usage__col--right {
  width: 100%;
  display: none;
  margin: 1rem;
}
@media (min-width: 40em) {
  .usage__col--right {
    width: 70%;
    display: block;
  }
}
.usage__accordion {
  display: flex;
  flex-wrap: wrap;
  width: 100%;
  border-radius: 8px;
  box-shadow: 0px 3px 6px 0px #00000029;
  background-color: #ffffff;
  padding: 0.5rem 1rem 0.5rem 1rem;
  margin-bottom: 1rem;
  position: relative;
  align-items: center;
  cursor: pointer;
  transition: all 0.3s;
}
@media (min-width: 40em) {
  .usage__accordion {
    padding: 0.5rem 2rem 0.5rem 1rem;
    height: auto;
  }
}
.usage__accordion:last-child {
  margin-bottom: 0;
}
.usage__accordion:before {
  content: "";
  height: 2.5rem;
  width: 3px;
  background-color: salmon;
  position: absolute;
  top: 0.5rem;
  left: 0;
  border-radius: 20px;
  transform-origin: top left;
  transform: scaleY(1);
}
@media (min-width: 40em) {
  .usage__accordion:before {
    height: calc(100% - 1rem);
  }
}
.usage__accordion:after {
  content: "";
  background-image: url("../images/chevron-right.svg");
  position: absolute;
  opacity: 1;
  right: 0.5rem;
  width: 1.5rem;
  height: 1.5rem;
  background-size: 100%;
  top: 1rem;
  background-repeat: no-repeat;
  transition: all 0.3s;
}
@media (min-width: 40em) {
  .usage__accordion:after {
    opacity: 0.3;
    background-size: 100%;
    width: 1.5rem;
    height: 1.5rem;
    top: 50%;
    transform: translate(0, -50%);
  }
}
.usage__accordion.active, .usage__accordion:hover {
  opacity: 1;
}
@media (min-width: 40em) {
  .usage__accordion.active, .usage__accordion:hover {
    transform: scale(1.05);
  }
}
.usage__accordion.active:after, .usage__accordion:hover:after {
  opacity: 1;
}
.usage__accordion.active:after {
  transform: rotate(90deg);
}
@media (min-width: 40em) {
  .usage__accordion.active:after {
    transform: translate(0, -50%);
  }
}
.usage__accordion.payroll:before {
  background-color: #26a69a;
}
.usage__accordion.leave:before {
  background-color: #2196f3;
}
.usage__accordion.attendance:before {
  background-color: #ef6c00;
}
.usage__accordion.meal:before {
  background-color: #f06292;
}
.usage__accordion.claim:before {
  background-color: #ef5350;
}
.usage__accordion.award:before {
  background-color: #ba68c8;
}
.usage__accordion.education:before {
  background-color: #43a047;
}
.usage__accordion.disciplinary:before {
  background-color: #7986cb;
}
.usage__accordion.employeeprofile:before {
  background-color: #78909c;
}
.usage__accordion.features:before {
  background-color: #795548;
}
.usage__accordion.mobileattendance:before {
  background-color: #0B968B;
}
.usage__accordion .accordion__icon {
  width: 2.5rem;
  height: 2.5rem;
}
.usage__accordion .accordion__icon img {
  width: 2.5rem;
  height: 2.5rem;
}
.usage__accordion .accordion__txt {
  padding-left: 0.5rem;
  width: calc(100% - 2.5rem);
}
.usage__accordion .accordion__title {
  font-size: 1rem;
  margin-bottom: 0.125rem;
}
@media (min-width: 40em) {
  .usage__accordion .accordion__title {
    font-size: 0.75rem;
  }
}
@media (min-width: 71.875em) {
  .usage__accordion .accordion__title {
    font-size: 1rem;
  }
}
.usage__accordion .accordion__desc {
  font-size: 0.75rem;
  color: #78909c;
  display: none;
}
@media (min-width: 71.875em) {
  .usage__accordion .accordion__desc {
    display: block;
  }
}
.usage__accordion .accordion__content {
  display: none;
}
@media (max-width: ) {
  .usage__accordion .accordion__content.d-m-none {
    display: none !important;
  }
}
.usage__accordion .accordion__content img {
  width: 100%;
}
.usage__accordion .accordion__content p {
  margin-top: 0.25rem;
}
.usage__content {
  width: 100%;
}
.usage__content .content--img-container {
  margin-top: 0.5rem;
  overflow: hidden;
  width: 100%;
  height: 30rem;
  background: #ffffff;
  position: relative;
  border: 1px solid #f3f3f3;
  border-radius: 1rem;
}
.usage__content .content--txt {
  margin-top: 1.5rem;
}
.usage__content .content--txt p {
  font-size: 1.125rem;
}
.usage__content .content--img {
  position: absolute;
  top: 0;
  left: 0;
}
.usage__content .content--img--01.img-payroll {
  top: -30px;
  left: -30px;
}
.usage__content .content--img--02.img-payroll {
  top: 30px;
  left: 320px;
}
.usage__content .content--img--01.img-payroll {
  top: -30px;
  left: -30px;
}
.usage__content .content--img--02.img-payroll {
  top: 30px;
  left: 320px;
}
.usage__content .content--img--01.img-leave {
  left: -80px;
  top: -100px;
  z-index: 1;
}
.usage__content .content--img--02.img-leave {
  left: 220px;
  top: -140px;
}
.usage__content .content--img--01.img-attendance {
  left: -50px;
  top: -40px;
  z-index: 1;
}
.usage__content .content--img--02.img-attendance {
  left: 320px;
  top: -100px;
}
.usage__content .content--img--01.img-mobile-attendance {
  left: 60px;
  top: -70px;
}
.usage__content .content--img--02.img-mobile-attendance {
  left: 360px;
  top: -210px;
}
.usage__content .content--img--01.img-meal {
  left: -30px;
  top: 90px;
}
.usage__content .content--img--02.img-meal {
  left: 380px;
  top: -100px;
}
.usage__content .content--img--01.img-claim {
  left: -50px;
  top: 40px;
}
.usage__content .content--img--02.img-claim {
  left: 290px;
  top: -280px;
}
.usage__content .content--img--01.img-award {
  left: -30px;
  top: -10px;
  z-index: 1;
}
.usage__content .content--img--02.img-award {
  left: 290px;
  top: -280px;
}
.usage__content .content--img--01.img-education {
  left: 0;
  top: 0;
}
.usage__content .content--img--02.img-education {
  left: 400px;
  top: -10px;
}
.usage__content .content--img--01.img-employeeprofile {
  top: 20px;
  left: 50%;
  transform: translate(-50%, 0%);
}
.usage__content .content--img--01.img-employeeprofile img {
  width: 340px;
}

@keyframes underlineY {
  from {
    transform: scaleY(1);
  }
  to {
    transform: scaleY(0);
  }
}
/* NAV */
header {
  background-color: #eef4f5;
  z-index: 99;
  margin-top: 0px;
  transition: 0.5s all;
  position: sticky;
  top: 0;
}

header.sticky {
  background-color: #ffffff;
  box-shadow: 0 4px 12px -2px rgba(0, 0, 0, 0.2);
}
header.sticky .nav-logo {
  height: 40px;
  line-height: 40px;
}
header.sticky .nav-logo img {
  height: 40px;
}
header.sticky ul.nav-menu li {
  background-color: #ffffff;
}
@media screen and (min-width: 767px) {
  header.sticky ul.nav-menu li {
    background-color: transparent;
  }
}
header.sticky nav.navigation {
  padding: 12px 0;
}
header.sticky .nav-toggle {
  width: 40px;
  height: 40px;
  top: 12px;
  right: 12px;
}

header.sub-sticky {
  position: sticky;
  background-color: #ffffff;
  top: 0;
  box-shadow: 0 4px 12px -2px rgba(0, 0, 0, 0.2);
}
header.sub-sticky .nav-logo {
  height: 40px;
  line-height: 40px;
}
header.sub-sticky .nav-logo img {
  height: 40px;
}
header.sub-sticky ul.nav-menu li {
  background-color: #ffffff;
}
header.sub-sticky nav.navigation {
  padding: 12px 0;
}
header.sub-sticky .nav-toggle {
  width: 40px;
  height: 40px;
  top: 12px;
  right: 12px;
}

nav.navigation {
  max-width: 80rem;
  margin: auto;
  position: relative;
  z-index: 2;
  padding: 16px 0;
}
@media screen and (min-width: 767px) {
  nav.navigation {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
  }
}

.nav-logo {
  height: 50px;
  line-height: 50px;
  padding: 0 20px;
  color: #ffffff;
  font-weight: 700;
  text-transform: uppercase;
}
@media (min-width: 40em) {
  .nav-logo {
    padding: 0 32px;
  }
}
.nav-logo img {
  height: 50px;
  transition: 0.3s all;
}

ul.nav-menu {
  margin: 0;
  padding-right: 20px;
  display: flex;
  align-items: center;
  flex-direction: column;
}
@media screen and (min-width: 767px) {
  ul.nav-menu {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    position: relative;
    flex-direction: row;
    padding-right: 32px;
  }
}

ul.nav-menu li {
  list-style: none;
}

.sticky .cta-container {
  background: #ffffff !important;
}

ul.nav-menu li.cta-container {
  display: flex;
  padding: 0 24px;
}
@media (min-width: 71.875em) {
  ul.nav-menu li.cta-container {
    padding: 0;
    background: none;
    margin-left: 24px;
  }
}

ul.nav-menu li a.btn-cta {
  margin: auto;
  box-shadow: none;
}

ul.nav-menu li a.nav-menu-link {
  height: 50px;
  line-height: 50px;
  padding: 8px;
  margin: 0 8px;
  position: relative;
  color: #2f2f2f;
  text-decoration: none;
  -webkit-transition-duration: 0.3s;
  -o-transition-duration: 0.3s;
  transition-duration: 0.3s;
}
@media (min-width: 40em) {
  ul.nav-menu li a.nav-menu-link {
    color: #2f2f2f;
  }
}
ul.nav-menu li a.nav-menu-link:after {
  background: none repeat scroll 0 0 transparent;
  content: "";
  display: block;
  height: 2px;
  left: 50%;
  position: absolute;
  background: #00ab9d;
  transition: width 0.3s ease 0s, left 0.3s ease 0s;
  width: 0;
  border-radius: 10px;
  bottom: 0;
}
ul.nav-menu li a.nav-menu-link:hover:after, ul.nav-menu li a.nav-menu-link.active:after {
  width: 100%;
  left: 0;
}
.nav-toggle {
  display: none;
  position: absolute;
  top: 16px;
  right: 16px;
  width: 50px;
  height: 50px;
  cursor: pointer;
}

span.icon-bar {
  position: absolute;
  right: 12px;
  display: block;
  width: 26px;
  height: 2px;
  background-color: #3b78a9;
  -webkit-transition-duration: 0.3s;
  -o-transition-duration: 0.3s;
  transition-duration: 0.3s;
}

.icon-bar:nth-child(1) {
  top: 17px;
}

.icon-bar:nth-child(2) {
  top: 24px;
}

.icon-bar:nth-child(3) {
  top: 31px;
}

.nav-overlay {
  position: absolute;
  right: 0;
  bottom: 0;
  left: 0;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 1;
  opacity: 0;
  visibility: hidden;
  -webkit-transition-duration: 0.3s;
  -o-transition-duration: 0.3s;
  transition-duration: 0.3s;
  position: fixed;
}

.nav-overlay.active {
  opacity: 1;
  visibility: visible;
}

/* ICON BARS ANIMATION */
.nav-toggle.active .icon-bar:nth-child(1) {
  top: 24px;
  -webkit-transform: rotate(45deg);
  -moz-transform: rotate(45deg);
  transform: rotate(45deg);
}

.nav-toggle.active .icon-bar:nth-child(2) {
  width: 0;
}

.nav-toggle.active .icon-bar:nth-child(3) {
  top: 24px;
  -webkit-transform: rotate(-45deg);
  -moz-transform: rotate(-45deg);
  transform: rotate(-45deg);
}

/* MEDIAQUERIES */
@media screen and (max-width: 767px) {
  ul.nav-menu {
    position: absolute;
    width: 100%;
    height: 0;
    padding: 0;
    overflow: hidden;
  }

  ul.nav-menu.active {
    height: auto;
    text-align: center;
  }

  ul.nav-menu li {
    width: 100%;
    background-color: #eef4f5;
  }

  ul.nav-menu li a.nav-menu-link {
    width: 100%;
    padding: 8px;
    text-align: center;
  }

  ul.nav-menu.active li.cta-container {
    padding: 16px 0;
  }

  .nav-toggle {
    display: block;
  }
}
@media screen and (min-width: 768px) {
  .nav-overlay.active {
    visibility: hidden;
    opacity: 0;
  }
}
@keyframes logoBlinker {
  0% {
    opacity: 1;
  }
  30% {
    opacity: 0.7;
  }
  50% {
    opacity: 1;
  }
  100% {
    opacity: 1;
  }
}
footer {
  padding: 1rem;
  background: #2f2f2f;
  color: #ffffff;
  text-align: center;
  font-size: 0.75rem;
}

/* Goals */
.goals__bg {
  height: 100%;
}
.goals__bg--img {
  background-image: url("../images/goals-bg-01.jpg");
  background-position: center center;
  background-size: cover;
  height: 100%;
  z-index: -2;
  position: absolute;
  top: 0;
  left: 0;
  width: 50%;
}
.goals__bg--img img {
  display: none;
}
.goals__bg--img.reverse {
  right: 0;
  left: auto;
  background-image: url("../images/goals-bg-02.jpg");
}
.goals__bg--img:before {
  content: "";
  width: 100%;
  position: absolute;
  z-index: 1;
  opacity: 0.3;
  top: 0;
  left: 0px;
  right: 0px;
  height: 100%;
  background: linear-gradient(90deg, #3b76ab, #4ca984);
}
.goals__row {
  display: flex;
}
.goals__title {
  font-size: 1.5rem;
  margin-bottom: 1.25rem;
}
@media (min-width: 40em) {
  .goals__title {
    margin-bottom: 2.5rem;
    font-size: 2rem;
  }
}
.goals__content {
  width: 50%;
  margin-left: auto;
  padding: 1.5rem;
}
.goals__content.reverse {
  margin-right: auto;
  margin-left: 0;
}

.animated-code {
  position: absolute;
  width: 80px;
  height: 80px;
}
.animated-code#animatedCode01 {
  bottom: 134px;
  right: -30px;
  width: 180px;
  height: 180px;
}
.animated-code#animatedCode02 {
  top: 0px;
  right: 200px;
  width: 140px;
  height: 140px;
}
.animated-code#animatedCode04 {
  top: 115px;
  right: 190px;
  width: 70px;
  animation-duration: 5s;
}
.animated-code#animatedCode05 {
  top: 130px;
  right: 275px;
  width: 70px;
  animation-duration: 7s;
}
.animated-code#animatedCode06 {
  top: 70px;
  right: 340px;
  width: 70px;
  animation-duration: 15s;
}
.animated-code#animatedCode07 {
  top: 55px;
  right: 130px;
  width: 70px;
  animation-duration: 10s;
}
.animated-code#animatedCode03 {
  position: relative;
}

.shapeBase {
  animation-iteration-count: infinite;
  animation-name: pulseAnimation;
  animation-timing-function: ease-in-out;
  animation-duration: 5s;
}

@keyframes pulseAnimation {
  0%, 100% {
    transform: scale(1);
  }
  50% {
    transform: scale(0.8);
  }
}
/* Goals */
.value__row {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  margin: 0 -1rem 1rem;
}
@media (min-width: 71.875em) {
  .value__row {
    flex-direction: row;
  }
  .value__row.reverse {
    flex-direction: row-reverse;
  }
}
.value__title {
  font-size: 1.25rem;
  margin-bottom: 0.5rem;
}
@media (min-width: 40em) {
  .value__title {
    margin-bottom: 1rem;
    font-size: 1.75rem;
  }
}
.value__img {
  width: 100%;
  padding: 1rem;
  position: relative;
}
@media (min-width: 71.875em) {
  .value__img {
    width: 50%;
  }
}
.value__img img.background-layer {
  position: absolute;
  left: 0;
  top: 0;
  z-index: -1;
}
.value__content {
  width: 100%;
  padding: 1rem;
}
@media (min-width: 71.875em) {
  .value__content {
    width: 50%;
  }
}
.value__content.reverse {
  margin-right: auto;
  margin-left: 0;
}
.value__animated-content .animated-icon {
  position: absolute;
}
.value__animated-content .animated-icon#animatedValue01 {
  bottom: 0;
  left: 0;
  width: 80px;
}

.cookie-alert {
  position: fixed;
  bottom: 0;
  width: 100%;
  left: 0;
  margin: 0 !important;
  z-index: 999;
  opacity: 0;
  background-color: #ffffff;
  transform: translateY(100%);
  transition: all 500ms ease-out;
  padding: 1rem 1rem;
  box-shadow: 0px 0px 12px 3px #ececec;
}
.cookie-alert p {
  margin: 0;
  padding: 0;
}
.cookie-alert.show {
  opacity: 1;
  transform: translateY(0%);
  transition-delay: 1000ms;
}
.cookie-alert .cookie-body {
  display: flex;
  align-items: center;
  flex-direction: column;
}
@media (min-width: 40em) {
  .cookie-alert .cookie-body {
    flex-direction: row;
  }
}
.cookie-alert .btn-container {
  display: flex;
  margin-left: auto;
  margin-top: 1rem;
}
@media (min-width: 40em) {
  .cookie-alert .btn-container {
    margin-top: 0;
  }
}
.cookie-alert .btn-cookie {
  color: #212529;
  margin: 0 0.5rem;
  display: inline-block;
  font-weight: 400;
  text-align: center;
  vertical-align: middle;
  user-select: none;
  background-color: transparent;
  border: 1px solid transparent;
  padding: 0.375rem 0.75rem;
  font-size: 1rem;
  line-height: 1.5;
  border-radius: 0.25rem;
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}
.cookie-alert .btn-cookie.reject {
  background-color: #eef4f5;
  border-color: #eef4f5;
}
.cookie-alert .btn-cookie.reject:hover {
  background-color: #e1e8e9;
  border-color: #e1e8e9;
}
.cookie-alert .btn-cookie.accept {
  color: #fff;
  background-color: #00ab9d;
  border-color: #00ab9d;
}
.cookie-alert .btn-cookie.accept:hover {
  background-color: #019b8e;
  border-color: #019b8e;
}

.letstalk__bg {
  background: linear-gradient(90deg, #3b76ab, #4ca984);
}
.letstalk__title {
  font-size: 1.875rem;
  color: #ffffff;
  font-weight: bold;
  text-align: center;
  padding: 12px;
}
@media (min-width: 40em) {
  .letstalk__title {
    font-size: 3.75rem;
  }
}
.letstalk__subtitle {
  font-size: 1rem;
  color: #ffffff;
  opacity: 0.6;
  text-align: center;
}
@media (min-width: 40em) {
  .letstalk__subtitle {
    font-size: 1.5rem;
  }
}
.letstalk__content {
  color: #ffffff;
}
.letstalk__cta {
  text-align: center;
  margin-top: 24px;
  display: flex;
  gap: 16px;
  justify-content: center;
}
.letstalk__button {
  display: flex;
  gap: 6px;
}
@media (min-width: 71.875em) {
  .letstalk__img {
    position: absolute;
  }
}
.letstalk__img img {
  width: 30%;
}
@media (min-width: 71.875em) {
  .letstalk__img img {
    width: 100%;
  }
}
.letstalk__img--left {
  display: flex;
  justify-content: flex-start;
  opacity: 0.7;
}
.letstalk__img--left img {
  max-width: 60px;
  animation: tilt-shaking 3s linear infinite;
}
@media (min-width: 71.875em) {
  .letstalk__img--left {
    top: 0;
    left: 10%;
  }
  .letstalk__img--left img {
    max-width: 180px;
  }
}
.letstalk__img--right {
  display: flex;
  justify-content: flex-end;
  margin-top: 24px;
  opacity: 0.9;
}
.letstalk__img--right img {
  max-width: 90px;
  animation: tilt-shaking 4s linear infinite;
}
@media (min-width: 71.875em) {
  .letstalk__img--right {
    bottom: 0;
    right: 10%;
  }
  .letstalk__img--right img {
    max-width: 220px;
  }
}

@keyframes tilt-shaking {
  0% {
    transform: rotate(0deg);
  }
  5% {
    transform: rotate(5deg);
  }
  10% {
    transform: rotate(0eg);
  }
  15% {
    transform: rotate(-5deg);
  }
  20% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(0deg);
  }
}