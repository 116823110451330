html {
    font-size: 100%;
    box-sizing: border-box;
    scroll-behavior: smooth;
}

*,
*::before,
*::after {
    // box-sizing: inherit;
    box-sizing: border-box;
}

body {
    margin: 0;
    padding: 0;
    font-family: var(--font-inter);
    color: #2f2f2f;
}
