@use "../util/" as *;

/* Goals */
.value {
  &__row {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    margin: 0 rem(-16) rem(16);

    @include breakpoint(large) {
      flex-direction: row;

      &.reverse {
        flex-direction: row-reverse;
      }
    }
  }

  &__title {
    font-size: rem(20);
    margin-bottom: rem(8);

    @include breakpoint(medium) {
      margin-bottom: rem(16);
      font-size: rem(28);
    }
  }

  &__img {
    width: 100%;
    padding: rem(16);
    position: relative;

    @include breakpoint(large) {
      width: 50%;
    }

    img.background-layer {
      position: absolute;
      left: 0;
      top: 0;
      z-index: -1;
    }
  }

  &__content {
    width: 100%;
    padding: rem(16);

    @include breakpoint(large) {
      width: 50%;
    }

    &.reverse {
      margin-right: auto;
      margin-left: 0;
    }
  }

  &__animated-content {
    .animated-icon {
      position: absolute;

      &#animatedValue01 {
        bottom: 0;
        left: 0;
        width: 80px;
      }
    }
  }
}
