@use "../util/" as *;

.showcase {
  &__bg {
    // background: linear-gradient(to top, #edfaf9, #ffffff);
    background-image: url("../images/thumbnail-app-big.jpg");
    background-repeat: no-repeat;
    background-position: center center;
    background-attachment: fixed;
    background-size: cover;
    z-index: 1;

    &--color-top {
      background: linear-gradient(to top, rgba(237, 250, 249, 1), rgba(255, 255, 255, 0.6), rgba(237, 250, 249, 1));
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      z-index: -1;
    }
  }

  &__row {
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    padding-bottom: rem(32);

    @include breakpoint(medium) {
      flex-wrap: nowrap;
      padding-bottom: 0;
      gap: rem(32);
      flex-direction: row;
    }
  }

  &__app {
    display: flex;
    padding-top: rem(32);
    padding-bottom: rem(24);
    width: 100%;
    justify-content: center;
    gap:rem(16)
  }

  &__app-btn {
    img {
      height: 40px;
    }
  }

  &__thumbnail {
    width: 100%;
    display: flex;
    align-items: flex-end;
    justify-content: center;

    @include breakpoint(medium) {
      width: 50%;
    }

    .bg-thumbnail {
      background-image: url("../images/product-thumbnail-01-desktop.png");
      background-repeat: no-repeat;
      background-position: right bottom;
      background-size: cover;
      position: absolute;
      width: 100%;
      height: 100%;
      top: 0;
      left: 0;
    }

    .bg-extend {
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      background: linear-gradient(to top, rgba(237, 250, 249, 0.9) 50%, rgba(255, 255, 255, 0.2) 100%);
      z-index: 1;
    }

    .mobile-ss {
      width: 100%;
      // max-width: 300px;
      max-width: 460px;
      margin: auto;
      z-index: 2;
    }

    .thumbnail {
      &__pattern {
        .pattern {
          &__style {
            position: absolute;
            z-index: 2;

            img {
              width: 100%;
            }

            &--01 {
              bottom: 12%;
              left: 5%;
              animation-duration: 11s;
              width: 100px;
              height: 100px;
            }

            &--02 {
              // top: 20%;
              // left: -10%;
              left: -2%;
              top: 38%;
              animation-duration: 12s;
              width: 100px;
              height: 100px;
            }

            &--03 {
              top: 21%;
              right: -5%;
              width: 90px;
              height: 90px;
              animation-duration: 13s;
            }

            &--04 {
              // bottom: 39%;
              top: 12%;
              left: -7%;
              width: 90px;
              animation-duration: 14s;
            }

            &--05 {
              bottom: 25%;
              right: 3%;
              width: 100px;
              animation-duration: 15s;
            }

            &--06 {
              bottom: -3%;
              right: 11%;
              width: 100px;
              animation-duration: 18s;
            }
          }
        }
      }
    }
  }

  &__content {
    width: 100%;
    padding: rem(12);
    background: rgba(255, 255, 255, 0.4);
    backdrop-filter: blur(4px);

    @include breakpoint(medium) {
      width: 50%;
      padding: rem(24);
    }

    h3 {
      font-size: rem(24);
      font-weight: bold;
      margin-bottom: rem(16);
    }

    p {
      margin: rem(16) 0;
    }
  }

  &__divider {
    height: 4px;
    background-color: #99ddd8;
    width: 80px;
    border-radius: 4px;
  }
}

.floatBase {
  animation-fill-mode: forwards;
  animation-iteration-count: infinite;
  animation-name: float2;
  animation-timing-function: ease-in-out;
}

@keyframes float2 {
  0% {
    transform: translatey(0);
  }
  50% {
    transform: translatey(20px);
  }
  to {
    transform: translatey(0);
  }
}
