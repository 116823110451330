@use "../util/" as *;

.cookie-alert {
  position: fixed;
  bottom: 0;
  width: 100%;
  left: 0;
  // bottom: 15px;
  // right: 15px;
  // width: 320px;
  margin: 0 !important;
  z-index: 999;
  opacity: 0;
  background-color: #ffffff;
  transform: translateY(100%);
  transition: all 500ms ease-out;
  padding: rem(16) rem(16);
  box-shadow: 0px 0px 12px 3px #ececec;

  p {
    margin: 0;
    padding: 0;
  }

  &.show {
    opacity: 1;
    transform: translateY(0%);
    transition-delay: 1000ms;
  }

  .cookie-body {
    display: flex;
    align-items: center;
    flex-direction: column;

    @include breakpoint(medium) {
      flex-direction: row;
    }
  }

  .btn-container {
    display: flex;
    margin-left: auto;
    margin-top: rem(16);

    @include breakpoint(medium) {
      margin-top: 0;
    }
  }

  .btn-cookie {
    color: #212529;
    // background-color: #f8f9fa;
    // border-color: #f8f9fa;
    margin: 0 rem(8);
    display: inline-block;
    font-weight: 400;
    text-align: center;
    vertical-align: middle;
    user-select: none;
    background-color: transparent;
    border: 1px solid transparent;
    padding: 0.375rem 0.75rem;
    font-size: 1rem;
    line-height: 1.5;
    border-radius: 0.25rem;
    transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out,
      box-shadow 0.15s ease-in-out;

    &.reject {
      // color: #fff;
      background-color: #eef4f5;
      border-color: #eef4f5;

      &:hover {
        background-color: #e1e8e9;
        border-color: #e1e8e9;
      }
    }

    &.accept {
      color: #fff;
      background-color: #00ab9d;
      border-color: #00ab9d;

      &:hover {
        background-color: #019b8e;
        border-color: #019b8e;
      }
    }
  }
}

// .card {
//   display: flex;
//   flex-direction: column;
//   min-width: 0;
//   word-wrap: break-word;
//   background-color: #fff;
//   background-clip: border-box;
//   border: 1px solid rgba(0,0,0,.125);
//   border-radius: rem(4);
// }

// .alert-cookie-policy{
//   display: none;
//   border-radius: 0;
//   position: fixed;
//   bottom:0;
//   top:auto;
//   left:0;
//   right: 0;
//   z-index: 999;
// }
// .alert-cookie-policy .alert{
//   border-radius: 0;
// }
