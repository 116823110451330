@use "../util/" as *;

/* Goals */
.goals {
  &__bg {
    height: 100%;
    // height: 100vh;

    &--img {
      background-image: url("../images/goals-bg-01.jpg");
      background-position: center center;
      background-size: cover;
      height: 100%;
      z-index: -2;
      position: absolute;
      top: 0;
      // width: 100%;
      left: 0;
      width: 50%;

      img {
        display: none;
      }

      &.reverse {
        // margin-left: auto;
        right: 0;
        left: auto;
        background-image: url("../images/goals-bg-02.jpg");
      }

      &:before {
        content: "";
        width: 100%;
        position: absolute;
        z-index: 1;
        opacity: 0.3;
        top: 0;
        left: 0px;
        right: 0px;
        height: 100%;
        background: linear-gradient(90deg, #3b76ab, #4ca984);
      }

      // &:after {
      //   content: "";
      //   width: 100%;
      //   position: absolute;
      //   z-index: -1;
      //   opacity: 0.3;
      //   top: 0;
      //   left: 0px;
      //   right: 0px;
      //   height: 100%;
      //   background: linear-gradient(180deg, rgba(255, 255, 255, 0.9) 50%, rgba(255, 255, 255, 0.2) 100%);
      // }
    }

    // &:before {
    //   content: "";
    //   width: 100%;
    //   position: absolute;
    //   z-index: -1;
    //   opacity: 0.3;
    //   top: 0;
    //   left: 0px;
    //   right: 0px;
    //   height: 100%;
    //   background: linear-gradient(90deg, #3b76ab, #4ca984);
    // }

    // &:after {
    //   content: "";
    //   width: 100%;
    //   position: absolute;
    //   z-index: -1;
    //   opacity: 0.3;
    //   top: 0;
    //   left: 0px;
    //   right: 0px;
    //   height: 100%;
    //   background: linear-gradient(180deg, rgba(255, 255, 255, 0.9) 50%, rgba(255, 255, 255, 0.2) 100%);
    // }
  }

  &__row {
    display: flex;
  }

  &__title {
    font-size: rem(24);
    margin-bottom: rem(20);

    @include breakpoint(medium) {
      margin-bottom: rem(40);
      font-size: rem(32);
    }
  }

  &__content {
    // max-width: rem(900);
    width: 50%;
    // margin: rem(300) auto rem(100);
    margin-left: auto;
    padding: rem(24);
    // max-width: 600px;

    &.reverse {
      margin-right: auto;
      margin-left: 0;
    }
  }

  // &__content {
  //   // max-width: rem(900);
  //   width: 50%;
  //   // margin: rem(300) auto rem(100);
  //   margin-left:auto;
  //   padding: rem(24);
  //   background: rgba(255, 255, 255, 0.4);
  //   backdrop-filter: blur(4px);
  //   text-align: center;
  //   // box-shadow:0 5px 25px 0 rgb(147 200 222 / 37%)
  //   // box-shadow: rgba(255, 255, 255, 0.3) 0px 19px 38px, rgba(255, 255, 255, 0.22) 0px 15px 12px;
  //   box-shadow: rgba(255, 255, 255, 0.1) 0px 10px 50px;
  // }
}
