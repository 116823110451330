@use "../util/" as *;

.figuredata {
  &__row {
    justify-content: center;
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(rem(270), 1fr));
    width: 100%;
    grid-gap: rem(24);

    @include breakpoint(medium) {
      grid-gap: rem(32);
    }
  }

  &__col {
    display: flex;
    flex-direction: column;
    padding: rem(16) rem(24);
    align-items: center;
    justify-content: center;
    box-shadow: 4px 4px 9px rgba(0, 0, 0, 0.2), -4px -4px 9px rgba(255, 255, 255, 0.2);
    border-radius: 16px;
    text-align: center;
    width: 100%;
    margin: auto;
    max-width: rem(300);
    background-color: #eef4f5;

    @include breakpoint(medium) {
      max-width: none;
    }

    // &:hover {
    //   // transform: translateY(200px);
    // }
  }

  &__figure {
    font-weight: bold;
    font-size: rem(28);
    color: #2f2f2f;

    @include breakpoint(medium) {
      font-size: rem(40);
    }
  }

  &__txt {
    font-size: rem(14);
    padding-top: rem(4);
    margin: 0;
    color: rgb(36, 48, 54);

    @include breakpoint(medium) {
      padding-top: rem(8);
      font-size: rem(16);
    }
  }
}

// .shapeBase {
//   animation-iteration-count: infinite;
//   animation-name: pulseAnimation;
//   animation-timing-function: ease-in-out;

//   &:nth-child(1) {
//     animation-duration: 5s;
//   }

//   &:nth-child(2) {
//     animation-duration: 6s;
//   }

//   &:nth-child(3) {
//     animation-duration: 7s;
//   }

//   &:nth-child(4) {
//     animation-duration: 8s;
//   }
// }

// @keyframes pulseAnimation {
//   0%,
//   100% {
//     transform: scale(1);
//   }
//   50% {
//     transform: scale(0.9);
//   }
// }

//number
.numbers {
  &__window {
    display: inline-block;
    overflow: hidden;
    width: 26px;
    height: 1em;

    &__digit {
      font: inherit;
      word-break: break-all;
      display: block;
      width: 0;
      padding: 0 0.52em 0 0;
      margin: 0 auto;
      overflow: inherit;
      animation: counting 0.4s steps(10) forwards infinite;

      &:before {
        content: attr(data-fake);
        display: inline-block;
        width: 100%;
        height: auto;
      }

      &--1 {
        animation-iteration-count: 3;
      }

      &--2 {
        animation-iteration-count: 6;
      }

      &--3 {
        animation-iteration-count: 9;
      }

      &--4 {
        animation-iteration-count: 12;
      }

      &--5 {
        animation-iteration-count: 15;
      }

      &--6 {
        animation-iteration-count: 18;
      }
    }
  }
}

@keyframes counting {
  100% {
    transform: translate3d(0, -10em, 0);
  }
}
