@use "../util/" as *;

.whywork {
    &__row {
        display: flex;
        gap: rem(16);
        justify-content: center;
        flex-wrap: wrap;
    }

    &__col {
        width: 100%;
        margin: auto;
        transform: scale(1);
        transition: all 0.4s;

        @include breakpoint(medium) {
            max-width: rem(320);
        }
    }

    &__animate {
        border-radius: 50%;
        display: flex;
        justify-content: center;
        box-shadow: 9px 9px 18px rgba(0, 171, 157, 0.2), -9px -9px 18px rgba(255, 255, 255, 1);
        margin: auto;
        position: relative;
        background-color: #eef4f5;
        width: 280px;
        height: 280px;

        @include breakpoint(medium) {
            width: 320px;
            height: 320px;
        }
    }

    &__content {
        padding: rem(16) rem(0);
        margin-top: rem(16);
        text-align: center;
        z-index: 9;
        transition: all 0.3s;
        text-shadow: 6px 11px 24px #ffffff;

        h4 {
            font-size: rem(18);
            margin-bottom: rem(12);
            margin-top: 0;
        }

        p {
            margin: 0;
        }
    }
}

@keyframes bounce {
    0%,
    20%,
    50%,
    80%,
    100% {
        transform: translateY(0);
    }
    40% {
        transform: translateY(-30px);
    }
    60% {
        transform: translateY(-15px);
    }
}

// .shapeBase {
//     animation-iteration-count: infinite;
//     animation-name: pulseAnimation;
//     animation-timing-function: ease-in-out;

//     &:nth-child(1) {
//         animation-duration: 5.5s;
//     }

//     &:nth-child(2) {
//         animation-duration: 6.5s;
//     }

//     &:nth-child(3) {
//         animation-duration: 4.5s;
//     }
// }

// @keyframes pulseAnimation {
//     0%,
//     100% {
//         transform: scale(1);
//     }
//     50% {
//         transform: scale(0.9);
//     }
// }
