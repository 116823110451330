@use "../util/" as *;

.features {
  &__grid {
    display: flex;
    grid-gap: rem(32);
    // grid-template-columns: repeat(auto-fit, minmax(320px, 1fr));
    // grid-template-rows: auto;
    // grid-template-columns: repeat(auto-fit, minmax(320px, 1fr));
    justify-content: center;
    flex-wrap: wrap;

    @include breakpoint(large) {
      // grid-template-columns: repeat(auto-fit, minmax(380px, 1fr));
    }
  }

  &__item {
    text-align: center;
    padding: rem(16);

    max-width: 380px;

    // box-shadow: 0 0 rem(16) rgba(0, 0, 0, 0.1);
    // border-radius: 8px;

    .item {
      &__icon {
        width: rem(80);
        height: rem(80);
        margin-bottom: rem(16);
        margin-left: auto;
        margin-right: auto;
        // background-color: #00ab9d;
        background: linear-gradient(90deg, #3b76ab, #4ca984);
        border-radius: 50%;
        display: flex;
        align-items: center;
        justify-content: center;
        box-shadow: rem(4) rem(5) rem(16) rgba(52, 147, 147, 0.5);

        img {
          width: rem(40);
          height: rem(40);
        }
      }

      &__title {
        h4 {
          font-size: rem(18);
          font-weight: bold;
          margin-bottom: rem(8);
          margin-top: 0;

          @include breakpoint(medium) {
            font-size: rem(24);
          }
        }
      }

      &__desc {
        p {
          margin: 0;
        }
      }
    }
  }
}
