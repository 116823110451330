@use "../util/" as *;

/* NAV */
header {
  background-color: #eef4f5;
  z-index: 99;
  margin-top: 0px;
  transition: 0.5s all;
  position: sticky;
  top: 0;
}

header.sticky {
  background-color: #ffffff;

  box-shadow: 0 4px 12px -2px rgba(0, 0, 0, 0.2);

  .nav-logo {
    height: 40px;
    line-height: 40px;

    img {
      height: 40px;
    }
  }

  ul.nav-menu li {
    background-color: #ffffff;

    @media screen and (min-width: 767px) {
      // background-color: salmon;
      background-color: transparent;
    }
  }

  nav.navigation {
    padding: 12px 0;
  }

  .nav-toggle {
    width: 40px;
    height: 40px;
    top: 12px;
    right: 12px;
  }
}

header.sub-sticky {
  position: sticky;
  background-color: #ffffff;
  top: 0;
  box-shadow: 0 4px 12px -2px rgba(0, 0, 0, 0.2);

  .nav-logo {
    height: 40px;
    line-height: 40px;

    img {
      height: 40px;
    }
  }

  ul.nav-menu li {
    background-color: #ffffff;
  }

  nav.navigation {
    padding: 12px 0;
  }

  .nav-toggle {
    width: 40px;
    height: 40px;
    top: 12px;
    right: 12px;
  }
}

nav.navigation {
  max-width: rem(1280);
  margin: auto;
  position: relative;
  z-index: 2;
  padding: 16px 0;

  @media screen and (min-width: 767px) {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
  }
}
.nav-logo {
  height: 50px;
  line-height: 50px;
  padding: 0 20px;
  color: #ffffff;
  font-weight: 700;
  text-transform: uppercase;

  @include breakpoint(medium) {
    padding: 0 32px;
  }

  img {
    height: 50px;
    transition: 0.3s all;
    // animation-name: logoBlinker;
    // animation-duration: 1.8s;
    // animation-iteration-count: infinite;
    // animation-timing-function: ease-in-out;
    // animation-direction: alternate;
  }
}

ul.nav-menu {
  margin: 0;
  padding-right: 20px;
  display: flex;
  align-items: center;
  flex-direction: column;

  @media screen and (min-width: 767px) {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    position: relative;
    flex-direction: row;
    padding-right: 32px;
  }
}

ul.nav-menu li {
  list-style: none;
}

.sticky .cta-container {
  background: #ffffff !important;
}

ul.nav-menu li.cta-container {
  display: flex;
  padding: 0 24px;

  @include breakpoint(large) {
    padding: 0;
    background: none;
    margin-left: 24px;
  }
}

ul.nav-menu li a.btn-cta {
  margin: auto;
  box-shadow: none;
}

ul.nav-menu li a.nav-menu-link {
  height: 50px;
  line-height: 50px;
  padding: 8px;
  margin: 0 8px;
  position: relative;
  color: #2f2f2f;
  text-decoration: none;
  -webkit-transition-duration: 0.3s;
  -o-transition-duration: 0.3s;
  transition-duration: 0.3s;

  @include breakpoint(medium) {
    color: #2f2f2f;
  }

  &:after {
    background: none repeat scroll 0 0 transparent;
    content: "";
    display: block;
    height: 2px;
    left: 50%;
    position: absolute;
    background: #00ab9d;
    transition: width 0.3s ease 0s, left 0.3s ease 0s;
    width: 0;
    border-radius: 10px;
    bottom: 0;

    @include breakpoint-down(small) {
      // bottom: 8px;
    }
  }

  &:hover:after,
  &.active:after {
    width: 100%;
    left: 0;

    @include breakpoint-down(small) {
      // width: 20%;
      // left: 40%;
    }
  }
}

.nav-toggle {
  display: none;
  position: absolute;
  top: 16px;
  right: 16px;
  width: 50px;
  height: 50px;
  cursor: pointer;
}

span.icon-bar {
  position: absolute;
  right: 12px;
  display: block;
  width: 26px;
  height: 2px;
  background-color: #3b78a9;
  -webkit-transition-duration: 0.3s;
  -o-transition-duration: 0.3s;
  transition-duration: 0.3s;
}
.icon-bar:nth-child(1) {
  top: 17px;
}
.icon-bar:nth-child(2) {
  top: 24px;
}
.icon-bar:nth-child(3) {
  top: 31px;
}
.nav-overlay {
  position: absolute;
  // top: 82px;
  right: 0;
  bottom: 0;
  left: 0;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 1;
  opacity: 0;
  visibility: hidden;
  -webkit-transition-duration: 0.3s;
  -o-transition-duration: 0.3s;
  transition-duration: 0.3s;
  position: fixed;
}
.nav-overlay.active {
  opacity: 1;
  visibility: visible;
}

/* ICON BARS ANIMATION */
.nav-toggle.active .icon-bar:nth-child(1) {
  top: 24px;
  -webkit-transform: rotate(45deg);
  -moz-transform: rotate(45deg);
  transform: rotate(45deg);
}

.nav-toggle.active .icon-bar:nth-child(2) {
  width: 0;
}

.nav-toggle.active .icon-bar:nth-child(3) {
  top: 24px;
  -webkit-transform: rotate(-45deg);
  -moz-transform: rotate(-45deg);
  transform: rotate(-45deg);
}

/* MEDIAQUERIES */
@media screen and (max-width: 767px) {
  ul.nav-menu {
    position: absolute;
    // top: 50px;
    width: 100%;
    height: 0;
    padding: 0;
    overflow: hidden;
  }

  ul.nav-menu.active {
    height: auto;
    text-align: center;
  }

  ul.nav-menu li {
    width: 100%;
    background-color: #eef4f5;
  }

  ul.nav-menu li a.nav-menu-link {
    width: 100%;
    padding: 8px;
    text-align: center;
  }

  ul.nav-menu.active li.cta-container {
    padding: 16px 0;
  }

  .nav-toggle {
    display: block;
  }
}

@media screen and (min-width: 768px) {
  .nav-overlay.active {
    visibility: hidden;
    opacity: 0;
  }
}

@keyframes logoBlinker {
  0% {
    opacity: 1;
  }

  30% {
    opacity: 0.7;
  }

  50% {
    opacity: 1;
  }

  100% {
    opacity: 1;
  }
}
