@use "../util/" as *;

.hero {
  &__container {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    // gap: rem(40);

    @include breakpoint(medium) {
      // gap: rem(80);
    }
  }

  &__bg {
    display: flex;
    align-items: center;
    justify-content: center;

    @include breakpoint(large) {
      // height: calc(100vh - 143px);
      // @media(min-height: 600px) {
        height: 100vh;
      // }
      
    }

    &:before {
      content: "";
      width: 100%;
      position: absolute;
      z-index: -2;
      // top: -143px;
      top: 0;
      left: 0px;
      right: 0px;
      height: calc(100vh + 150px);
      // max-height: 1014px;
      // background: linear-gradient(90deg, #F4F7FC 0%, #00ab9d);
      background: linear-gradient(90deg, #3b76ab, #4ca984);
    }

    &:after {
      content: "";
      width: 100%;
      position: absolute;
      z-index: -2;
      // top: -143px;
      top: 0;
      left: 0px;
      right: 0px;
      height: calc(100vh + 150px);
      // max-height: 1014px;
      background: linear-gradient(180deg, rgba(255, 255, 255, 0.9) 50%, rgba(255, 255, 255, 0.2) 100%);
    }
  }

  &__row {
    display: flex;
    margin-bottom: rem(40);

    @include breakpoint(medium) {
      margin-bottom: rem(80);
    }
  }

  &__content {
    text-align: left;
    line-height: 1.3;

    .content {
      &__header {
        font-style: italic;
        font-size: rem(16);
        color: #78909c;
        margin-bottom: rem(16);

        @include breakpoint(medium) {
          font-size: rem(20);
        }
      }

      &__liner {
        font-size: rem(32);
        margin-bottom: rem(16);
        font-weight: bold;

        @include breakpoint(medium) {
          font-size: rem(60);
        }

        span.highlight {
          background: linear-gradient(90deg, #2d4e8c, #3b76ab, #4ca984);
          -webkit-background-clip: text;
          background-clip: text;
          -webkit-background-clip: text;
          -webkit-text-fill-color: rgba(255, 255, 255, 0.001);
          letter-spacing: -2.1px;
          white-space: nowrap;
        }
      }

      &__typewriter {
        font-size: rem(48);
        // margin-bottom: rem(32);
        font-weight: bold;
        display: block;

        @include breakpoint(medium) {
          font-size: rem(90);
        }

        @include breakpoint(large) {
          display: inline;
        }
      }
    }
  }

  &__img {
    width: 40%;
  }
}
