@use "../util/" as *;

.container {
    padding: 0 rem(20);
    margin: 0 auto;
    @include breakpoint(medium) {
        // max-width: rem(768);
        padding: 0 rem(40);
    }
    @include breakpoint(large) {
        max-width: rem(1280);
        padding: 0 rem(32);
    }

    &.fluid {
        padding: 0;
        // max-width: none;
    }

    &.mw960 {
        max-width: rem(960);

        @include breakpoint-down(medium) {
            // padding: 0;
            // background: salmon;
        }
    }
}

p {
    line-height: 1.5;
}

a.href-link {
    color: var(--color-primary);

    &:hover {
        color: #06897e;
    }
}

.d-none {
    display: none;
}

.d-md-none {
    @include breakpoint(medium) {
        display: none;
    }
}

.d-lg-none {
    @include breakpoint(large) {
        display: none;
    }
}

.d-block {
    display: block;
}

.d-md-block {
    @include breakpoint(medium) {
        display: block;
    }
}

.d-lg-block {
    @include breakpoint(large) {
        display: block;
    }
}

.m-auto {
    margin: auto;
}

.img-responsive {
    display: none;

    &.show-mobile {
        display: block;

        @include breakpoint(medium) {
            display: none;
        }
    }

    &.show-tablet {
        @include breakpoint(medium) {
            display: block;
        }

        @include breakpoint(large) {
            display: none;
        }
    }

    &.show-desktop {
        @include breakpoint(large) {
            display: block;
        }
    }
}

.position-relative {
    position: relative;
}

.btn-primary {
    display: inline-block;
    background-color: var(--color-primary);
    color: var(--color-primary-text-light);
    padding: rem(16) rem(32);
    border-radius: 30px;
    transition: all 0.3s;
    box-shadow: 9px 9px 18px rgba(0, 171, 157, 0.2), -9px -9px 18px rgba(255, 255, 255, 1);

    &:hover {
        background-color: #06897e;
    }
}

.btn-highlight {
    display: inline-block;
    background-color: #64b99e;
    color: #2f2f2f;
    padding: rem(16) rem(32);
    border-radius: 30px;
    transition: all 0.3s;
    box-shadow: 9px 9px 18px rgba(0, 171, 157, 0.2), -9px -9px 18px rgba(255, 255, 255, 1);

    &:hover {
        background-color: #4aa387;
    }
}

.btn-cta {
    background: linear-gradient(90deg, #3b76ab, #4ca984);
    border-radius: 29px;
    // padding: rem(14) rem(14) rem(14) rem(22);
    padding: rem(8) rem(24);
    // margin-left: rem(24);
    border: none;
    outline: none;
    display: flex !important;
    align-items: center;
    cursor: pointer;
    // margin-right: -4px;
    position: relative;
    top: 1px;
    height: 50px;
    z-index: 10;
    box-shadow: 0 10px 13px 0 rgb(209 211 237 / 39%);
    font-weight: bold;
    transition: all 0.3s;
    color: #2f2f2f;

    span {
        // img {
        // background-color: #cccccc;
        // background-color: #ff00ff;
        // }
        // border-radius: 50%;
        // background-color: #00ab9d;
        width: 24px;
        height: 24px;
        margin-right: rem(8);
        // width: 36px;
        // height: 36px;
        // display: flex;
        // align-items: center;
        // justify-content: center;
    }

    &:before {
        content: "";
        z-index: -1;
        top: 3px;
        left: 3px;
        position: absolute;
        background: #fff;
        width: calc(100% - 6px);
        height: calc(100% - 6px);
        border-radius: 30px;
    }

    &:hover {
        &:before {
            background: transparent;
        }
        color: #ffffff;
    }
}

.btn-cta-02 {
    background: linear-gradient(90deg, #3b76ab, #4ca984);
    border-radius: 29px;
    padding: rem(16) rem(24);
    border: none;
    outline: none;
    display: inline-block;
    cursor: pointer;
    position: relative;
    top: 1px;
    z-index: 10;
    // box-shadow: 0 10px 13px 0 rgb(209 211 237 / 39%);
    box-shadow: 0 0 #0000, 0 0 #0000, 0 0 #0000, 0 0 #0000, rgba(180, 0, 9, 0.2);
    font-weight: bold;
    transition: all 0.3s;
    color: #ffffff;

    &:before {
        content: "";
        z-index: -1;
        top: 3px;
        left: 3px;
        position: absolute;
        background: transparent;
        width: calc(100% - 6px);
        height: calc(100% - 6px);
        border-radius: 30px;
    }

    &:hover {
        &:before {
            background: linear-gradient(90deg, #2c679b, #358867);
        }
        // color: #2f2f2f;
    }
}

.btn-cta-03 {
    background: linear-gradient(90deg, #fff, #fff);
    // background:#ffffff;
    border-radius: 29px;
    padding: rem(16) rem(24);
    border: none;
    outline: none;
    // display: inline-block;
    display: flex;
    cursor: pointer;
    position: relative;
    top: 1px;
    z-index: 10;
    // box-shadow: 0 10px 13px 0 rgb(209 211 237 / 39%);
    box-shadow: 0 0 #0000, 0 0 #0000, 0 0 #0000, 0 0 #0000, rgba(180, 0, 9, 0.2);
    font-weight: bold;
    transition: all 0.3s;
    color: #2e2e2e;

    &:before {
        content: "";
        z-index: -1;
        top: 3px;
        left: 3px;
        position: absolute;
        background: transparent;
        width: calc(100% - 6px);
        height: calc(100% - 6px);
        border-radius: 30px;
    }

    &:hover {
        &:before {
            // background: linear-gradient(90deg, #2c679b, #358867);
            background: rgba(84, 84, 84, 0.1);
        }
        // color: #2f2f2f;
    }

    span {
        width: 24px;
        height: 24px;
        margin-right: rem(8);
    }
}

.overflow-x-hidden {
    overflow-x: hidden;
}
