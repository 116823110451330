@use "../util/" as *;

.contact {
  &__bg {
    background-color: #f2f9ff;
  }

  &__grid {
    display: grid;
    grid-gap: rem(16);
    grid-template-columns: 100%;
    grid-template-areas:
      "header"
      "highlight"
      "form";

    @include breakpoint(medium) {
      grid-gap: rem(32);
    }

    @include breakpoint(large) {
      // grid-gap: rem(32);
      grid-template-columns: 33% calc(67% - rem(32));
      grid-template-areas:
        "highlight header"
        "highlight form";
    }
  }

  &__highlight {
    grid-area: highlight;
    margin: 0 auto;

    &--img {
      margin-bottom: rem(16);

      img {
        max-width: rem(384);
      }
    }

    &--detail {
      .detail {
        &__row {
          display: flex;
          align-items: center;
          margin-bottom: rem(8);
          justify-content: center;

          @include breakpoint(large) {
            justify-content: flex-start;
          }

          &.pdpa-row {
            margin-top: rem(24);
            font-size: 80%;
          }
        }

        &__icon {
          width: rem(24);
          margin-right: rem(8);

          img {
            width: rem(24);
          }
        }

        &__txt {
          color: #78909c;

          a {
            text-decoration: underline;
            color: var(--color-primary);

            &:hover {
              color: #06897e;
            }
          }
        }
      }
    }
  }

  &__header {
    grid-area: header;

    h2 {
      margin-bottom: rem(16);
      font-size: rem(24);
      text-align: center;

      @include breakpoint(medium) {
        font-size: rem(32);
      }

      @include breakpoint(large) {
        text-align: left;
      }
    }
    p {
      margin: 0;
    }
  }

  &__success {
    background: #f2f9ff;
    position: absolute;
    // opacity: 0.4;
    height: 102%;
    width: 100%;
    top: 0;
    left: 0;
    z-index: 99;
    display: none;

    &.sent-success {
      display: block;
    }

    #contactSuccess {
      width: 50%;
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      z-index: 100;
    }
  }

  &__form {
    grid-area: form;
    position: relative;

    .form {
      &__row {
        display: flex;
        flex-wrap: wrap;

        @include breakpoint(medium) {
          flex-wrap: nowrap;
          // gap: rem(32);
          margin: rem(-16);
        }
      }

      &__col50per {
        width: 100%;

        @include breakpoint(medium) {
          width: 50%;
          margin: rem(16);
        }
      }

      &__col100per {
        width: 100%;

        @include breakpoint(medium) {
          margin: rem(16);
        }
      }

      &__group {
        margin-bottom: rem(16);
        width: 100%;
        font-size: rem(12);
        position: relative;

        @include breakpoint(medium) {
          font-size: rem(16);
          margin-bottom: rem(8);
        }

        label {
          display: inline-block;
          margin-bottom: rem(8);
          font-weight: bold;

          &.form-check-label {
            font-weight: normal;
            margin-bottom: 0;
          }
        }

        .form-control {
          display: block;
          width: 100%;
          padding: 0.375rem 0.75rem;
          font-size: rem(12);
          line-height: 1.5;
          color: #495057;
          background-color: #fff;
          background-clip: padding-box;
          border: 1px solid #ced4da;
          border-radius: 0.25rem;
          transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
          font-family: var(--font-inter);

          @include breakpoint(medium) {
            font-size: rem(16);
          }

          &:focus {
            color: #495057;
            background-color: #fff;
            border-color: #00ab9d;
            outline: 0;
            box-shadow: 0 0 0 0.2rem rgb(0 171 157 / 25%);
          }

          // &.has-error {
          //   border-color: #af0808;
          //   outline: 0;
          //   box-shadow: 0 0 0 0.2rem rgba(179, 7, 7, 0.25);
          // }
        }

        textarea {
          overflow: auto;
          resize: vertical;
        }

        input {
          overflow: visible;
        }

        .form-check {
          display: flex;
          align-items: flex-start;
          gap: rem(8);
        }
      }

      &__validate {
        display: none;
        color: #b12020;
        font-size: rem(10);
        margin-top: rem(4);
        // position: absolute;
        // bottom: rem(-16);

        &.has-error {
          display: block;
        }
      }
    }

    .form-submit {
      margin-top: rem(12);
    }
  }
}

.grecaptcha-badge {
  opacity: 0;

  &.show {
    opacity: 1;
    z-index: 999;
  }
}

/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type="number"] {
  -moz-appearance: textfield;
}

.table-striped > tbody > tr:nth-of-type(odd) > * {
  --bs-table-accent-bg: #f2f9ff;

  // &:hover,
  // &:focus{
  //   --bs-table-accent-bg: #bed3e5 !important;
  // }
}

// .table-striped>tbody>tr:hover,
// .table-striped>tbody>tr:focus{
//   --bs-table-accent-bg: #bed3e5 !important;
// }

#tbodyContact.first-load {
  tr {
    display: none;
  }
  tr:nth-child(-n + 9) {
    display: table-row;
  }
}

#contact01 {
  max-width: 500px;
}
