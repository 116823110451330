@use "../util/" as *;

.products {
    &__bg {
        background: linear-gradient(to top, #edfaf9, #ffffff);
    }

    &__row {
        display: flex;
        gap: rem(32);
        align-items: center;
        flex-wrap: wrap;

        @include breakpoint(medium) {
            flex-wrap: nowrap;
        }
    }

    &__thumbnail {
        width: 100%;

        @include breakpoint(medium) {
            width: 50%;
        }
    }

    &__content {
        width: 100%;

        @include breakpoint(medium) {
            width: 50%;
        }

        h3 {
            font-size: rem(24);
            font-weight: bold;
            margin-bottom: rem(16);
        }

        p {
            margin: rem(16) 0;
        }
    }

    &__divider {
        height: 4px;
        background-color: #99ddd8;
        width: 80px;
        border-radius: 4px;
    }
}
