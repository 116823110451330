@use "../util/" as *;

.shape {
  &__content {
  }

  &__animate {
    position: absolute;
    z-index: -1;
    &--01 {
      right: 130px;
      top: 170px;
      animation-duration: 11s;
    }
    &--02 {
      left: 79px;
      top: 31px;
      animation-duration: 12s;
    }
    &--03 {
      left: 421px;
      top: 399px;
      animation-duration: 13s;
    }
    &--04 {
      right: 407px;
      top: 601px;
      animation-duration: 14s;
    }
    &--05 {
      left: 530px;
      top: 607px;
      animation-duration: 15s;
    }
    &--06 {
      left: 200px;
      top: 190px;
      animation-duration: 16s;
    }
    &--07 {
      left: 432px;
      top: 67px;
      animation-duration: 17s;
    }
    &--08 {
      left: 124px;
      top: 542px;
      animation-duration: 18;
    }
    &--09 {
      right: 544px;
      top: 23px;
      animation-duration: 19s;
    }
    &--10 {
      right: 305px;
      top: 332px;
      animation-duration: 20s;
    }
  }
}

.shapeAnimate {
  animation-iteration-count: infinite;
  animation-name: shapeAnimation;
  animation-timing-function: ease-in-out;
  animation-duration: 3s;

  &:nth-child(1) {
    animation-duration: 2s;
  }

  &:nth-child(2) {
    animation-duration: 3s;
  }

  &:nth-child(3) {
    animation-duration: 4s;
  }

  &:nth-child(4) {
    animation-duration: 5s;
  }

  &:nth-child(5) {
    animation-duration: 6s;
  }
}

@keyframes shapeAnimation {
  0%,
  100% {
    transform: scale(1);
  }
  50% {
    transform: scale(0.8);
  }
}

.bounceUpFloatBase {
  animation: bounceUp 1s, float 4s 1s infinite;
  animation-fill-mode: forwards;
  animation-timing-function: ease-in-out, ease-in-out;
  animation-delay: 0.2s, 0.27s;
  animation-duration: 0.7s, 8s;
  // animation: float 6s ease-in-out infinite;
}

@keyframes bounceUp {
  0% {
    transform: translateY(500px);
    opacity: 0;
  }
  60% {
    transform: translateY(-15px);
  }
  to {
    transform: translateY(0);
    opacity: 1;
  }
}

@keyframes float {
  0% {
    transform: translatey(0);
  }
  50% {
    transform: translatey(50px);
  }
  to {
    transform: translatey(0);
  }
}