@use "../util/" as *;

.landing {
  &__bg {
    background-image: url("../images/bg-landing.png");
    background-size: 100%;
    background-position: center bottom;
    background-repeat: no-repeat;
  }

  &__bgcolor {
    background-image: linear-gradient(to top, #f4f7fc, #ffffff);
    height: 200%;
    position: absolute;
    bottom: 0;
    width: 100%;
    z-index: -1;
  }

  &__row {
    display: flex;
    align-items: center;
  }

  &__content {
    width: 40%;

    .content {
      &__header {
        font-style: italic;
        font-size: rem(20);
        color: #78909c;
        margin-bottom: rem(16);
      }

      &__liner {
        font-size: rem(32);
        margin-bottom: rem(16);
      }

      &__typewriter {
        font-size: rem(60);
        margin-bottom: rem(32);
        font-weight: bold;
      }
    }
  }

  &__img {
    width: 60%;

    img {
      max-width: rem(675);
      display: block;
      margin: auto;
    }
  }
}
