@use "../util/" as *;

.coding {
  &__content {
    // position: absolute;
    // top: 0;
    // left: 0;

    .animate-item {
      // background: salmon;
      // border-radius: 50%;
      // display: flex;
      // align-items: center;
      // justify-content: center;
      // box-shadow: 0px 0px 4px #cccccc;
    }
  }
}

.animated-code {
  position: absolute;
  width: 80px;
  height: 80px;

  &#animatedCode01 {
    bottom: 134px;
    right: -30px;
    width: 180px;
    height: 180px;
  }

  &#animatedCode02 {
    top: 0px;
    right: 200px;
    width: 140px;
    height: 140px;
  }

  &#animatedCode04 {
    top: 115px;
    right: 190px;
    width: 70px;
    animation-duration: 5s;
  }

  &#animatedCode05 {
    top: 130px;
    right: 275px;
    width: 70px;
    animation-duration: 7s;
  }

  &#animatedCode06 {
    top: 70px;
    right: 340px;
    width: 70px;
    animation-duration: 15s;
  }

  &#animatedCode07 {
    top: 55px;
    right: 130px;
    width: 70px;
    animation-duration: 10s;
  }

  &#animatedCode03 {
    position: relative;
    // top: 330px;
    // right: 240px;
    // width: 30px;
    // height: 30px;
  }
}


.shapeBase {
  animation-iteration-count: infinite;
  animation-name: pulseAnimation;
  animation-timing-function: ease-in-out;
  animation-duration: 5s;

  // &:nth-child(1) {
  //   animation-duration: 5s;
  // }

  // &:nth-child(2) {
  //   animation-duration: 6s;
  // }

  // &:nth-child(3) {
  //   animation-duration: 7s;
  // }

  // &:nth-child(4) {
  //   animation-duration: 8s;
  // }
}

@keyframes pulseAnimation {
  0%,
  100% {
    transform: scale(1);
  }
  50% {
    transform: scale(0.8);
  }
}
