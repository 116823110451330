@use "../util/" as *;

footer {
  padding: rem(16);
  background: #2f2f2f;
  color: #ffffff;
  text-align: center;
  font-size: rem(12);
}


// footer style
// .footer-style{
//   &__item{
//     display: flex;
//     justify-content: center;
//     align-items: center;
//     margin-bottom: rem(16);
//     flex-direction: column;
//   }
// }