@use "../util/" as *;

.sublanding {
  &__bg {
    background: linear-gradient(to bottom, rgba(237, 250, 249, 1), rgba(255, 255, 255, 0));

    @include breakpoint(medium) {
      // height: 600px;
      padding-bottom: 200px;
    }

    &--gradient {
      &:before {
        content: "";
        width: 100%;
        position: absolute;
        z-index: -2;
        top: 0;
        left: 0px;
        right: 0px;
        // height: calc(100vh + 150px);
        height: 100%;
        background: linear-gradient(90deg, #3b76ab, #4ca984);
      }

      &:after {
        content: "";
        width: 100%;
        position: absolute;
        z-index: -2;
        top: 0;
        left: 0px;
        right: 0px;
        // height: calc(100vh + 150px);
        height: 100%;
        background: linear-gradient(180deg, rgba(255, 255, 255, 0.9) 50%, rgba(255, 255, 255, 0.2) 100%);
      }
    }

    &--payroll {
      background: linear-gradient(to top, rgba(0, 171, 157, 0.2), rgba(255, 255, 255, 0.1));
    }

    &--leave {
      background: linear-gradient(to top, rgba(33, 150, 243, 0.2), rgba(255, 255, 255, 0.1));
    }

    &--attendance {
      background: linear-gradient(to top, rgba(239, 108, 0, 0.2), rgba(255, 255, 255, 0.1));
    }

    &--mobileattendance {
      background: linear-gradient(to top, rgba(4, 137, 126, 0.2), rgba(255, 255, 255, 0.1));
    }

    &--claim {
      background: linear-gradient(to top, rgba(239, 83, 80, 0.2), rgba(255, 255, 255, 0.1));
    }

    &--employeeprofile {
      background: linear-gradient(to top, rgba(120, 144, 156, 0.2), rgba(255, 255, 255, 0.1));
    }

    &--features {
      background: linear-gradient(to top, rgba(121, 85, 72, 0.2), rgba(255, 255, 255, 0.1));
    }
  }

  &__bgcolor {
    background-image: linear-gradient(to top, #f4f7fc, #ffffff);
    height: 200%;
    position: absolute;
    bottom: 0;
    width: 100%;
    z-index: -1;
  }

  &__pagebtn {
    @include breakpoint(medium) {
      position: absolute;
      bottom: 180px;
    }

    &__container {
      // position: relative;
      // display: none;
      display: flex;
      align-items: center;
      flex-wrap: nowrap;
      width: calc(100% + 40px);
      margin-left: -20px;
      margin-bottom: -12px;

      @include breakpoint(medium) {
        // margin-left: 0;
        // display:flex;
        // width: 100%;
        // position: static;
      }
    }

    &__content {
      display: none;
      padding-left: 4px;
      padding-right: 4px;
      align-items: center;
      opacity: 0;
      transition: 0.3s all;
    }

    &__txt {
      color: #2f2f2f;
      margin-left: 12px;
      word-break: keep-all;
    }

    // background: #fff;
    background: rgba(255, 255, 255, 0);
    backdrop-filter: blur(24px);
    position: absolute;
    width: auto;
    display: flex;
    align-items: center;
    color: #00ab9d;
    text-align: left;
    z-index: 3;
    transition: 0.3s all;

    @include breakpoint(medium) {
      background: rgba(255, 255, 255, 0.4);
    }

    &:hover {
      background: rgba(255, 255, 255, 0.9);

      @include breakpoint(medium) {
        .sublanding__pagebtn__icon {
          width: 100%;
        }

        .sublanding__pagebtn__content {
          display: flex;
          // position: relative;
          // visibility: visible;
          opacity: 1;
        }
      }
    }

    @include breakpoint(medium) {
      width: auto;
    }

    &__icon {
      width: 40px;
      // width: auto;
      height: 40px;
      display: flex;
      align-items: center;
      transition: 0.3s all;
    }

    svg {
      color: #00ab9d;
      width: 40px;
    }

    &--prev {
      left: 0;
      // padding: 12px 16px 12px 0px;
      padding: 8px;
      margin-right: auto;
    }

    &--next {
      right: 0;
      // padding: 12px 0px 12px 16px;
      padding: 8px;
    }
  }

  &__row {
    display: flex;
    gap: rem(32);

    @include breakpoint-down(medium) {
      flex-direction: column-reverse;
    }
  }

  &__headline {
    width: 100%;
    padding-top: rem(290);

    @include breakpoint(medium) {
      padding-top: rem(240);
      // padding-top: rem(540);
      padding-bottom: rem(0);
    }

    @include breakpoint(large) {
      width: 40%;
      padding-top: rem(300);
      padding-bottom: rem(100);
    }
  }

  &__subheadline {
    // width: 100%;
    margin: auto;
    // width: 100%;

    h2 {
      // font-weight: normal;
      // text-transform: uppercase;
      // font-size: rem(16);
      // color: #657085;
      // letter-spacing: 3px;
      // padding-bottom: 4px;
    }

    .product-link {
      font-weight: normal;
      text-transform: uppercase;
      font-size: rem(16);
      color: #657085;
      letter-spacing: 3px;
      padding-bottom: 6px;
      padding-right: 12px;
      position: relative;

      &::after {
        // background: none repeat scroll 0 0 transparent;
        background: #00ab9d;
        border-radius: 10px;
        bottom: 0;
        content: "";
        display: block;
        height: 2px;
        position: absolute;
        transition: width 0.5s ease 0s, left 0.5s ease 0s;
        width: 0;
        left: 0;
      }

      &:hover {
        &::after {
          width: 100%;
        }
      }
    }
  }

  &__title {
    h1 {
      margin-bottom: rem(16);
      font-size: rem(26);

      @include breakpoint(medium) {
        font-size: rem(36);
      }
    }

    &.w_icon {
      display: flex;
      gap: 16px;
      align-items: center;
      margin-top: 24px;

      h1 {
        margin-bottom: 0;
      }
    }
  }

  &__divider {
    background-color: #00ab9d;
    height: 10px;
    width: 90px;
    margin-bottom: rem(24);
    border-radius: 40px;
  }

  &__content {
    max-width: rem(900);
    // margin: auto;

    p {
      font-size: rem(16);

      @include breakpoint(medium) {
        font-size: rem(20);
      }
    }
  }

  &__app {
    display: flex;
    padding-top: rem(8);
    padding-bottom: rem(24);
    width: 100%;
    gap:rem(16);
  }

  &__app-btn {
    img {
      height: 40px;
    }
  }
}

.phones__bg {
  background-image: url("../images/test-ss-01.png");
  background-position: center center;
  background-size: cover;
  height: 100%;
  left: 490px;
  position: absolute;
  top: -70px;
  width: 100%;
  opacity: 0.6;
}

.phone-screen-wrap {
  position: relative;
  width: auto;

  @include breakpoint(medium) {
    box-shadow: rgba(152, 163, 207, 0.3) 0px 20px 34px;
    margin-top: -180px;
    // background: #ffffff;
    background: rgba(255, 255, 255, 0.5);
    // background: linear-gradient(to bottom, rgba(237, 250, 249, 1), rgba(255, 255, 255, 0));
    margin-bottom: 60px;
    border-radius: 16px;
    padding-bottom: 6px;
    backdrop-filter: blur(12px);
  }

  @include breakpoint(large) {
    margin-top: -240px;
  }

  .pattern {
    &__style {
      position: absolute;
      z-index: 2;

      img {
        width: 100%;
      }

      &--01 {
        top: -8%;
        left: -4%;
        animation-duration: 11s;
        width: 100px;
        height: 100px;
      }

      &--02 {
        // top: 20%;
        // left: -10%;
        left: -2%;
        top: 38%;
        animation-duration: 12s;
        width: 100px;
        height: 100px;
      }

      &--03 {
        top: 21%;
        right: -5%;
        width: 90px;
        height: 90px;
        animation-duration: 13s;
      }

      &--04 {
        // bottom: 39%;
        top: 12%;
        left: -7%;
        width: 90px;
        animation-duration: 14s;
      }

      &--05 {
        bottom: 25%;
        right: 3%;
        width: 100px;
        animation-duration: 15s;
      }

      &--06 {
        bottom: -3%;
        right: 11%;
        width: 100px;
        animation-duration: 18s;
      }
    }
  }
}

.phone-screen {
  display: flex;
  justify-content: center;
  margin-left: auto;
  margin-top: -60px;

  &__coming-soon {
    width: auto;
    display: inline-block;
    bottom: 15%;
    right: 10%;
    position: absolute;
    padding: 6px 12px;
    background: #db0011;
    transform: rotate(-35deg);
    color: #ffffff;
    font-size: 14px;
    line-height: 100%;
  }

  &__present {
    // width: 50%;
    max-width: 360px;
    display: relative;

    &.d-m-none {
      display: none;

      @include breakpoint(medium) {
        display: block;
      }
    }
  }
}

// .single-phone {
//   height: 10rem;
//   position: absolute;
//   width: 100%;

//   // @include breakpoint(medium) {
//   //   position: relative;
//   // }

//   &__present {
//     height: auto;
//     position: absolute;
//     z-index: 1;
//     width: 400px;
//     top: 500px;
//     // pointer-events:none;

//     &--01 {
//       left: 0px;
//     }

//     &--02 {
//       left: 350px;
//     }

//     @include breakpoint(medium) {
//       position: absolute;
//       top: 0;
//     }
//   }
// }

.phone {
  // transform: rotate(-30deg);
  height: 17rem;
  transform: scale(0.48) rotate(-30deg);
  position: absolute;
  width: 100%;

  @include breakpoint(medium) {
    transform: scale(1) rotate(0deg);
    position: relative;
  }

  @include breakpoint(large) {
    position: absolute;
    left: 60px;
    height: 14rem;
  }

  &__present {
    // width: 300px;
    height: auto;
    position: absolute;
    z-index: 1;
    pointer-events: none;
    width: 530px;

    &--01 {
      left: -340px;
      top: -610px;
    }

    &--02 {
      left: 20px;
      top: -740px;
    }

    &--03 {
      left: -165px;
      top: -240px;
    }

    &--05 {
      left: 150px;
      top: -230px;
    }

    &--06 {
      left: 500px;
      top: -290px;
    }

    &--07 {
      left: 320px;
      top: 215px;
    }

    @include breakpoint(medium) {
      &--01 {
        left: -290px;
        top: -420px;
      }

      &--02 {
        top: -610px;
        left: 90px;
      }

      &--03 {
        left: -90px;
        top: -120px;
      }

      &--04 {
        left: 330px;
        top: -390px;
      }

      &--05 {
        left: 260px;
        top: -200px;
      }

      &--06 {
        left: 690px;
        top: -510px;
        display: block;
      }

      &--07 {
        left: 510px;
        top: -10px;
      }
    }

    img {
      width: 100%;
    }
  }
}

.group-feature {
  &__grid {
    display: flex;
    grid-gap: rem(40) rem(32);
    // grid-template-columns: repeat(auto-fit, minmax(320px, 1fr));
    // grid-template-rows: auto;
    // grid-template-columns: repeat(auto-fit, minmax(320px, 1fr));
    justify-content: center;
    flex-wrap: wrap;

    @include breakpoint(large) {
      // grid-template-columns: repeat(auto-fit, minmax(380px, 1fr));
    }
  }

  &__item {
    text-align: center;
    // padding: rem(16);
    // height: 100%;
    width: 100%;
    max-width: 380px;
    transform: scale(1);
    transition: 0.3s all;

    &--halfw {
      @include breakpoint(large) {
        max-width: 500px;
      }
      // text-align: left;
    }

    &:hover {
      // transform: scale(1.05);
      .group-feature__content {
        --_p: 0%;
      }

      .group-feature__icon {
        // border: 4px solid #00ab9d;
        margin-left: 30px;

        &::before {
          content: "";
          display: block;
          background: #ffffff;
          width: 60px;
          height: 60px;
          position: absolute;
          left: 50%;
          top: 50%;
          transform: translate(-50%, -50%);
          border-radius: 100%;
          z-index: -2;
          animation: spin 1s linear infinite;
          box-shadow: 0px 2px 5px #00ab9d;
        }
      }

      // .group-feature__content::after {
      //   position: absolute;
      //   width: 10px;
      //   height: 10px;
      //   background: #2d2d2d;
      //   bottom:0;
      //   left:0;
      // }
    }
  }

  &__icon {
    width: 60px;
    height: 60px;
    border-radius: 50%;
    // background: #effbf9;
    background-color: #ffffff;
    border: 4px solid transparent;
    margin: 0 auto -18px 40px;
    z-index: 2;
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;
    box-shadow: 0px 0px 4px 0px #00000040;
    transition: 0.3s all;

    // &::after {
    //   content: "";
    //   position: absolute;
    //   inset: 8px;
    //   background: white;
    //   z-index: -1;
    //   border-radius: 8px;
    // }
  }

  &__icon-wrap {
    z-index: 2;
    position: relative;
    // top: -40px;
  }

  &__content {
    border-top: 4px solid #00ab9d;
    border-radius: 4px;
    // background: #ffffff;
    background: linear-gradient(90deg, #effbf9 50%, #ffffff 0) var(--_p, 100%) / 200% no-repeat;
    padding: 24px 16px 16px;
    height: calc(100% - 32px);
    position: relative;
    box-shadow: 0px 4px 4px 0px #0000001e;
    transition: 0.5s all;

    // &::before {
    //   position: absolute;
    //   display: block;
    //   width: 10px;
    //   height: 10px;
    //   background: #2d2d2d;
    //   bottom: 0;
    //   left: 0;
    // }

    p {
      padding: 0;
      margin: 0;
      color: #657085;
    }
  }

  &__title {
    font-size: 20px;
    font-weight: bold;
    margin-bottom: 8px;
    color: #2f2f2f;
  }
}

.key-feature {
  &__container {
    display: flex;
    // margin-bottom: 60px;
  }

  &__content {
    border-radius: 8px;
    // box-shadow: 0px 0px 4px 0px #00000040;
    padding: 8px 24px;
    margin: auto;
    text-align: center;
    width: 100%;
    margin-bottom: 40px;
    font-size: 20px;
    font-weight: bold;

    .txt-sm {
      font-size: 70%;
      opacity: 0.7;
      display: block;
      font-weight: normal;
    }

    &.bg {
      &--payroll {
        // background: rgba(0, 171, 157, 0.1)
        background: #fff;
      }

      &--leave {
        background: linear-gradient(to top, rgba(33, 150, 243, 0.2), rgba(255, 255, 255, 0.1));
      }

      &--attendance {
        background: linear-gradient(to top, rgba(239, 108, 0, 0.2), rgba(255, 255, 255, 0.1));
      }

      &--mobileattendance {
        background: linear-gradient(to top, rgba(239, 108, 0, 0.2), rgba(255, 255, 255, 0.1));
      }

      &--claim {
        background: linear-gradient(to top, rgba(239, 83, 80, 0.2), rgba(255, 255, 255, 0.1));
      }

      &--employeeprofile {
        background: linear-gradient(to top, rgba(120, 144, 156, 0.2), rgba(255, 255, 255, 0.1));
      }

      &--features {
        background: linear-gradient(to top, rgba(121, 85, 72, 0.2), rgba(255, 255, 255, 0.1));
      }
    }
  }

  &__icon {
    border-radius: 50%;
    // box-shadow: 0px 2px 12px rgb(0 0 0/ 12%);
    box-shadow: 0.25rem 0.3125rem 1rem rgb(52 147 147 / 50%);
    width: 180px;
    height: 180px;
    display: flex;
    justify-content: center;
    align-items: center;
    background: #ffffff;
    // margin-bottom: -10px;
    z-index: 2;
    img {
      width: 150px;
      // position:absolute;
      // display: flex;
      // align-items: center;
      // justify-content: center;
    }
  }
}

@keyframes spin {
  from {
    transform: translate(-50%, -50%) rotate(0);
  }
  to {
    transform: translate(-50%, -50%) rotate(360deg);
  }
}

/**
 * ==============================================
 * Dot Flashing
 * ==============================================
 */
.snippet {
  display: flex;
  justify-content: center;
}
.dot-flashing {
  position: relative;
  width: 10px;
  height: 10px;
  border-radius: 5px;
  background-color: #00ab9d;
  color: #00ab9d;
  animation: dotFlashing 1s infinite linear alternate;
  animation-delay: 0.5s;
}

.dot-flashing::before,
.dot-flashing::after {
  content: "";
  display: inline-block;
  position: absolute;
  top: 0;
}

.dot-flashing::before {
  left: -15px;
  width: 10px;
  height: 10px;
  border-radius: 5px;
  background-color: #00ab9d;
  color: #00ab9d;
  animation: dotFlashing 1s infinite alternate;
  animation-delay: 0s;
}

.dot-flashing::after {
  left: 15px;
  width: 10px;
  height: 10px;
  border-radius: 5px;
  background-color: #00ab9d;
  color: #00ab9d;
  animation: dotFlashing 1s infinite alternate;
  animation-delay: 1s;
}

@keyframes dotFlashing {
  0% {
    background-color: #00ab9d;
  }
  50%,
  100% {
    background-color: #d3edeb;
  }
}
