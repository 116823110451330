@use "../util/" as *;

section {
    padding: rem(40) 0;
    scroll-margin-top: rem(60);
    @include breakpoint(large) {
        padding: rem(80) 0;
        scroll-margin-top: rem(80);
    }

    &.p-0 {
        padding: 0;
    }
}

.section {
    &__title {
        font-size: rem(24);
        margin-bottom: rem(20);
        text-align: center;
        @include breakpoint(medium) {
            margin-bottom: rem(40);
            font-size: rem(32);
        }
    }
}
