@use "../util/" as *;

.screenshot {
  &__bg {
    background-image: url("../images/thumbnail-app-bg-01.jpg");
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center center;
    background-attachment: fixed;
  }

  &__height {
    height: rem(300);
  }
}
